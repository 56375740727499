import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ErrorPageComponent} from './components/error-page/error-page.component';
import {BookingManagerComponent} from './components/booking-manager/booking-manager.component'; // CLI imports router

const routes: Routes = [
  {
    path: '',
    redirectTo: 'error',
    pathMatch: 'full',
    data: {error: 'EAB.COMMON.AVAILABLE_TIME_ERROR'}
  },
  {path: 'error', component: ErrorPageComponent},
  {path: ':calendarname', component: BookingManagerComponent}
];

// configures NgModule imports and exports
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
