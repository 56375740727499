import {Injectable} from '@angular/core';
import {CalendarEventDate} from '../models/calendar-event.model';
import {GoogleCalendarService} from './google-calendar.service';
import {SupportedCalendarsEnum} from '../models/supported-calendars.enum';
import {Office365CalendarService} from './office365-calendar.service';
import {OutlookLiveCalendarService} from './outlook-live-calendar.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  constructor(
    private readonly googleCalendar: GoogleCalendarService,
    private readonly office365CalendarService: Office365CalendarService,
    private readonly outlookLiveCalendarService: OutlookLiveCalendarService
  ) {}

  /**
   *
   * event
   * @return text representation of a VCALENDAR iCal file.<br/>
   * e.g:<br/>
   * <pre>
   BEGIN:VCALENDAR
   PRODID:-//Events Calendar//VENDOR 1.0//DE
   VERSION:2.0
   BEGIN:VEVENT
   DTSTAMP:20181124T153543Z
   DTSTART:20181105T000000
   DTEND:20190915T000000
   SUMMARY:Meeting with ...
   DESCRIPTION:Some description, even
   multiline is possible
   URL:https://www.mydomain.de/context/app/do.it?id=435&nr=30
   UID:20181124T153543Z-uidGen@domain.de
   END:VEVENT
   END:VCALENDAR
   * </pre>
   */
  public createEvent = (
    events: {
      start: Date;
      end: Date;
      summary: string;
      description?: string;
      location?: string;
      url?: string;
    }[]
  ) => {
    const formatDate = (date: Date | null): string => {
      if (!date) {
        return '';
      }
      // don't use date.toISOString() here, it will be always one day off (cause of the timezone)
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      const month =
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
      const year = date.getFullYear();
      const hour =
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
      const minutes =
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
      const seconds =
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      return `${year}${month}${day}T${hour}${minutes}${seconds}`;
    };
    let VCALENDAR = `BEGIN:VCALENDAR
    PRODID:-//Events Calendar//HSHSOFT 1.0//DE
    VERSION:2.0
    `;

    for (const event of events) {
      const timeStamp = formatDate(new Date());
      const uuid = `${timeStamp}`;
      /**
       * Don't ever format this string template!!!
       */
      const EVENT = `BEGIN:VEVENT
      DTSTAMP:${timeStamp}Z
      DTSTART:${formatDate(event.start)}
      DTEND:${formatDate(event.end)}
      SUMMARY:${event.summary}
      DESCRIPTION:${event.description}
      LOCATION:${event.location}
      UID:${uuid}
      END:VEVENT`;
      VCALENDAR += `${EVENT}
      `;
    }
    VCALENDAR += 'END:VCALENDAR';

    return VCALENDAR.replace(/  +/g, '');
  };

  public download = (filename: string, text: string) => {
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
    );
    element.setAttribute('download', `${filename}.ics`);
    element.setAttribute('target', '_blank');
    element.style.display = 'none';
    element.click();
  };

  public generateLink(
    calendarType: SupportedCalendarsEnum,
    data: CalendarEventDate
  ): string {
    switch (calendarType) {
      case SupportedCalendarsEnum.GOOGLE:
        return this.googleCalendar.generateUrl(data);
      case SupportedCalendarsEnum.OFFICE365:
        return this.office365CalendarService.generateUrl(data);
      case SupportedCalendarsEnum.OUTLOOK_LIVE:
        return this.outlookLiveCalendarService.generateUrl(data);
      default:
        throw new Error(`Provided calendar: ${calendarType} not supported`);
    }
  }
}
