import {TranslationsKeys} from '../core/api/models/translation.model';

export function getErrorBasedOnStatusCode(
  statusCode: number
): TranslationsKeys {
  switch (statusCode) {
    case 1000:
      return 'EAB.COMMON.AVAILABLE_TIME_ERROR';
    case 1001:
      return 'EAB.COMMON.INVALID_TIME_ERROR';
    case 2000:
      return 'EAB.COMMON.OVERLAP_ERROR';
    case 2001:
      return 'EAB.COMMON.GESPRACH_ERROR';
    case 2003:
      return 'EAB.COMMON.EMPTY_SUBJECT_ERROR';
    case 2004:
      return 'EAB.COMMON.START_GREATER_END_ERROR';
    case 2005:
      return 'EAB.COMMON.DURATION_LESS_MINIMUM_ERROR';
    case 2006:
      return 'EAB.COMMON.DURATION_MORE_MAXIMUM_ERROR';
    case 2007:
      return 'EAB.COMMON.AVAILABLE_SLOT_ERROR';
    case 2008:
      return 'EAB.COMMON.START_NOT_MATCH_START_ERROR';
    case 2009:
      return 'EAB.COMMON.LOCATION_DIFFERENT_ERROR';
    case 2010:
      return 'EAB.COMMON.NOT_IN_FUTURE_ERROR';
    case 3001:
      return 'EAB.COMMON.NO_SLOT_CALENDAR_ERROR';
    case 4001:
      return 'EAB.COMMON.NOT_FIND_CALENDAR_ERROR';
    case 4002:
      return 'EAB.COMMON.UNAUTHORIZED_CALENDAR_ERROR';
    case 5001:
      return 'EAB.COMMON.NOT_ACCOUNT_CALENDAR_ERROR';
  }
  return 'EAB.COMMON.GENERAL_ERROR';
}
