<div class="language-selector-container">
  <mat-form-field appearance="outline" class="no-validation-area">
    <mat-select [formControl]="languageFormControl">
      <mat-select-trigger>
        <div class="d-flex align-center">
          <mat-icon>language</mat-icon>&nbsp;
          <span *ngIf="languageFormControl.value" style="font-size: 18px">{{
            languageFormControl.value!!.value | i18n | async
          }}</span>
        </div>
      </mat-select-trigger>

      <mat-option *ngFor="let language of languages" [value]="language">{{
        language.value | i18n | async
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
