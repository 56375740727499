import {FormatNumberPipe} from '../../../pipes/format-number.pipe';

export type CustomValueFormatters = 'phoneNumberFormatter';

export const editableLabelCustomFormattersModel: {
  name: CustomValueFormatters;
  value: typeof FormatNumberPipe;
}[] = [
  {
    name: 'phoneNumberFormatter',
    value: FormatNumberPipe
  }
];
