import {ValidationErrors} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {ErrorMapperInterface} from '../models/editable-label-error-map.interface';
import {Pipe, PipeTransform} from '@angular/core';
import {FormFieldErrorEnum} from '../models/form.model';
import {I18nPipe} from './i18n.pipe';

@Pipe({
  name: 'formGroupFieldErrors',
  standalone: true
})
export class FormGroupFieldErrorsPipe implements PipeTransform {
  constructor(private readonly i18n: I18nPipe) {}

  public transform(
    errors: ValidationErrors | undefined | null,
    mapper: ErrorMapperInterface[]
  ): Observable<string | null> {
    if (!errors || Object.keys(errors).length === 0 || !mapper) {
      return of(null);
    }
    let firstValidationError = Object.keys(errors)[0];
    const detailedError = errors[firstValidationError];
    if (typeof detailedError === 'string') {
      firstValidationError = detailedError;
    }
    firstValidationError = firstValidationError.toUpperCase();
    const translationKey = mapper.find(
      // @ts-ignore
      (x) => x.key === FormFieldErrorEnum[firstValidationError]
    )?.translation;

    if (!translationKey) {
      console.group('FormGroupFieldErrorsPipe:transform');
      console.log('Current error', firstValidationError);
      console.log('All errors', errors);
      console.log('Error mapper', mapper);
      console.groupEnd();

      return of('No translation provided');
    }

    const t: (string | number)[] = this.getAdditionalDataBasedOnErrorType(
      FormFieldErrorEnum.MAXLENGTH,
      errors[Object.keys(errors)[0]]
    );

    return this.i18n.transform(translationKey, ...t);
  }

  private getAdditionalDataBasedOnErrorType(
    errorType: FormFieldErrorEnum,
    errorDetails: {[key: string]: string}
  ): (string | number)[] {
    switch (errorType) {
      case FormFieldErrorEnum.MAXLENGTH:
        const details = errorDetails as unknown as {
          requiredLength: number;
          actualLength: number;
        };
        return [details.requiredLength, details.actualLength];
      default:
        return [];
    }
  }
}
