import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {coreFeature} from '../../core/store/core/core.reducer';
import {Store} from '@ngrx/store';
import {I18nPipe} from '../../pipes/i18n.pipe';
import {GetDayNamePipe} from '../../pipes/get-day-name.pipe';
import {GetDatePipe} from '../../pipes/get-day.pipe';
import {GetMonthNamePipe} from '../../pipes/get-month-name.pipe';
import {GetYearPipe} from '../../pipes/get-year.pipe';

@Component({
  selector: 'app-appointment-time-range',
  standalone: true,
  imports: [
    CommonModule,
    I18nPipe,
    GetDayNamePipe,
    GetDatePipe,
    GetMonthNamePipe,
    GetYearPipe
  ],
  templateUrl: './appointment-time-range.component.html',
  styleUrls: ['./appointment-time-range.component.scss']
})
export class AppointmentTimeRangeComponent {
  public selectedHour$ = this.store.select(
    coreFeature.selectSelectedAppointment
  );

  public configuration$ = this.store.select(
    coreFeature.selectClientConfiguration
  );

  constructor(private readonly store: Store) {}
}
