import {Pipe, PipeTransform} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';

@Pipe({
  name: 'checkIfFormFieldRequired',
  pure: false,
  standalone: true
})
export class CheckIfFormFieldRequiredPipe implements PipeTransform {
  public transform(
    formGroup: FormGroup,
    controlKey: string,
    forceRequiredManually: boolean | null
  ): boolean {
    if (forceRequiredManually === true) {
      return true;
    } else if (forceRequiredManually === false) {
      return false;
    } else {
      return this.isRequiredField(formGroup, controlKey);
    }
  }

  private isRequiredField(formGroup: FormGroup, field: string) {
    const formField = formGroup.get(field);
    if (!formField?.validator) {
      return false;
    }

    const validator = formField.validator({} as AbstractControl);
    return validator && validator['required'];
  }
}
