import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {I18nPipe} from '../../pipes/i18n.pipe';
import {TranslationsKeys} from '../../core/api/models/translation.model';
import {Store} from '@ngrx/store';
import {coreFeature} from '../../core/store/core/core.reducer';
import {Subscription, take, tap} from 'rxjs';
import {setApplicationLanguage} from '../../core/store/core/core.actions';
import {SupportedLanguagesEnum} from '../../models/supported-languages.enum';

@Component({
  selector: 'app-language-selector',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    I18nPipe
  ],
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
  public languageFormControl = new FormControl<{
    key: SupportedLanguagesEnum;
    value: TranslationsKeys;
  } | null>(null);
  public languages: {key: SupportedLanguagesEnum; value: TranslationsKeys}[] = [
    {
      key: SupportedLanguagesEnum.EN,
      value: 'EAB.COMMON.ENGLISH'
    },
    {
      key: SupportedLanguagesEnum.DE,
      value: 'EAB.COMMON.GERMAN'
    }
  ];

  private readonly subscriptions = new Subscription();

  constructor(private readonly store: Store) {}

  public ngOnInit(): void {
    this.store
      .select(coreFeature.selectLanguage)
      .pipe(
        take(1),
        tap((selectedLanguage) => {
          const defaultLanguage = this.languages.find(
            (lang) => lang.key === selectedLanguage
          );
          if (!!defaultLanguage) {
            this.languageFormControl.setValue(defaultLanguage);
          } else {
            throw new Error('Default language is not defined');
          }
        })
      )
      .subscribe();
    this.subscriptions.add(
      this.languageFormControl.valueChanges.subscribe((value) => {
        this.store.dispatch(setApplicationLanguage({language: value!!.key}));
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
