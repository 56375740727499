import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-spinner-indicator',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './spinner-indicator.component.html'
})
export class SpinnerIndicatorComponent {
  @Input() public loading: boolean | null = false;
}
