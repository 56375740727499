import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCardModule} from '@angular/material/card';
import {DateAdapter, MatNativeDateModule} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {I18nPipe} from '../../pipes/i18n.pipe';
import {SpinnerIndicatorComponent} from '../../shared-components/spinner-indicator/spinner-indicator.component';
import {filter, Observable, Subscription} from 'rxjs';
import {CalendarHeaderComponent} from './calendar-header/calendar-header.component';
import {Store} from '@ngrx/store';
import {selectCalendarStartingDate} from '../../core/store/core/core.selectors';
import {coreFeature} from '../../core/store/core/core.reducer';
import {userSelectedAppointmentDay} from '../../core/store/core/core.actions';
import {CompanyInfoCardComponent} from '../../shared-components/company-info-card/company-info-card.component';
import {FlatCardComponent} from '../../shared-components/flat-card/flat-card.component';
import {CardComponent} from '../../shared-components/card/card.component';
import {TimePickerComponent} from '../../shared-components/time-picker/time-picker.component';
import {map} from 'rxjs/operators';
import {CalendarFromMondayDateAdapter} from '../../core/ui/adapters/calendar-from-monday.date.adapter';

@Component({
  selector: 'app-select-time',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatCardModule,
    MatNativeDateModule,
    MatButtonModule,
    MatIconModule,
    I18nPipe,
    SpinnerIndicatorComponent,
    CompanyInfoCardComponent,
    FlatCardComponent,
    CardComponent,
    TimePickerComponent
  ],
  templateUrl: './select-time.component.html',
  styleUrls: ['./select-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: DateAdapter, useClass: CalendarFromMondayDateAdapter}]
})
export class SelectTimeComponent implements OnInit, OnDestroy {
  public calendarHeader = CalendarHeaderComponent;
  public readonly customerConfiguration$ = this.store.select(
    coreFeature.selectClientConfiguration
  );
  public readonly suggestedTimesLoading$ = this.store.select(
    coreFeature.selectAppointmentSuggestionsLoading
  );
  public selectedDate$: Observable<Date> = this.store.select(
    coreFeature.selectSelectedAppointmentDayInCalendar
  );
  public minCalendarDate$ = this.store
    .select(selectCalendarStartingDate)
    .pipe(filter((x) => !!x));

  private readonly subscription: Subscription = new Subscription();

  constructor(
    private readonly dateAdapter: DateAdapter<Date>,
    private readonly store: Store,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    this.subscription.add(
      this.store
        .select(coreFeature.selectLanguage)
        .pipe(map((lang) => this.dateAdapter.setLocale(lang)))
        .subscribe()
    );

    this.subscription.add(
      this.store
        .select(coreFeature.selectAvailableDays)
        .subscribe((availableDays) => {
          this.dateFilter = (date: Date): boolean => {
            const result = !!availableDays.find(
              (item) => item === date.getDate()
            );
            return result;
          };
          this.changeDetectorRef.markForCheck();
        })
    );
  }

  public onSelectedDateChanged(date: Date | null) {
    this.store.dispatch(userSelectedAppointmentDay({date: date!!}));
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public dateFilter = (_: Date): boolean => {
    return true;
  };
}
