<div class="time-picker-container">
  <div
    *ngIf="(suggestedTimes$ | async)?.length; else no_slots"
    class="time-picker-container__title"
  >
    {{ 'EAB.COMMON.WHAT_TIME_BEST' | i18n | async }}</div
  >
  <ng-template #no_slots>
    <div class="time-picker-container__no-slots-available">
      <span>{{ 'EAB.COMMON.NO_TIME_AVAILABLE_FOR' | i18n | async }}</span>
    </div>
  </ng-template>

  <div class="time-picker-container__available-slots">
    <app-spinner-indicator [loading]="suggestedTimesLoading$ | async">
      <div
        *ngFor="let dayOfSuggestions of suggestedTimes$ | async"
        class="available-slot"
      >
        <div
          *ngFor="let hour of dayOfSuggestions.hours; let i = index"
          class="available-slot__time"
        >
          <div (click)="timeSelected(i)" class="time-container">
            <span class="time-container__hour"
              >{{ hour.startHour }}:{{ hour.startMinutes }} -
              {{ hour.endHour }}:{{ hour.endMinutes }}</span
            >
            <div
              *ngIf="(selectedIndex$ | async) !== i"
              class="time-container__date"
            >
              <span> {{ selectedDate$ | async | date }}</span>
            </div>
          </div>

          <button
            (click)="selected(hour)"
            *ngIf="(selectedIndex$ | async) === i"
            class="available-slot__continue"
            >{{ 'EAB.COMMON.CONTINUE' | i18n | async }}
          </button>
        </div>
      </div>
    </app-spinner-indicator>
  </div>
</div>
