<div class="appointment-section">
  <app-company-info-card></app-company-info-card>

  <app-flat-card class="appointment-details-container">
    <p class="appointment-details-container__title">{{
      (customerConfiguration$ | async)?.appointmentName
    }}</p>
    <div class="appointment-details-container__details">
      <mat-icon>event_available</mat-icon>
      <div class="location__info">
        <app-appointment-time-range></app-appointment-time-range>
      </div>
    </div>
  </app-flat-card>

  <div class="w-100">
    <app-button
      (click)="navigateToPreviousPage()"
      [buttonType]="'stroke'"
      [icon]="'chevron_left'"
      [label]="'EAB.COMMON.CHANGE_DATE'"
      [loading]="isAppointmentSaving$ | async"
    ></app-button>
  </div>
</div>

<app-card
  class="user-information-container"
  title="EAB.COMMON.CONFIRM_MEETING_FOR"
>
  <form
    [formGroup]="userInformation"
    class="user-information-container__content"
  >
    <div class="inline-fields">
      <app-input
        [controlKey]="'firstName'"
        [errorMapper]="ERROR_MAPPERS"
        [label]="'EAB.COMMON.FIRST_NAME'"
      ></app-input>

      <app-input
        [controlKey]="'lastName'"
        [errorMapper]="ERROR_MAPPERS"
        [label]="'EAB.COMMON.LAST_NAME'"
      ></app-input>
    </div>

    <app-input
      [controlKey]="'companyName'"
      [errorMapper]="ERROR_MAPPERS"
      [label]="'EAB.COMMON.COMPANY_NAME'"
    ></app-input>

    <app-input
      [controlKey]="'email'"
      [errorMapper]="ERROR_MAPPERS"
      [label]="'EAB.COMMON.EMAIL'"
    ></app-input>

    <app-input
      [controlKey]="'phone'"
      [errorMapper]="ERROR_MAPPERS"
      [label]="'EAB.COMMON.PHONE_NUMBER'"
      [valueFormatter]="'phoneNumberFormatter'"
    ></app-input>

    <app-text-area
      [controlKey]="'note'"
      [errorMapper]="ERROR_MAPPERS"
      [label]="'EAB.COMMON.NOTE'"
    ></app-text-area>
    <app-error-block
      [errorLabel]="createNewAppointmentError$ | async"
    ></app-error-block>

    <div class="form-confirm d-flex justify-end">
      <app-button
        (click)="save(selectedHour)"
        *ngIf="selectedHour$ | async as selectedHour"
        [label]="'EAB.COMMON.CONFIRM'"
        [loading]="isAppointmentSaving$ | async"
      ></app-button>
    </div>
  </form>
</app-card>
