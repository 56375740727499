import {Pipe, PipeTransform} from '@angular/core';
import {TranslationsKeys} from '../core/api/models/translation.model';
import {Observable, of, tap} from 'rxjs';
import {map} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {selectTranslation} from '../core/store/translations/translations.selectors';

const cachedKeys = new Map<string, string>();

@Pipe({
  name: 'i18n',
  standalone: true
})
export class I18nPipe implements PipeTransform {
  constructor(private readonly store: Store) {}

  public transform(
    key: TranslationsKeys | null,
    ...args: (string | number | undefined)[]
  ): Observable<string> {
    if (key === null) return of('');

    const cacheKey = `${key}-${JSON.stringify(args)}`;

    if (cachedKeys.has(cacheKey)) {
      return of(cachedKeys.get(cacheKey)!!);
    }

    let spited: string[] = [];
    try {
      spited = key.split('.').map((x) => x.toLowerCase());
    } catch (e) {
      console.error(`Delivered translation key ${key} is not valid`);
      cachedKeys.set(cacheKey, 'Invalid translation key');
      return of('Invalid translation key');
    }

    return this.store
      .select(selectTranslation(spited[2], spited[1], spited[0]))
      .pipe(
        tap((text) => {
          if (!text) {
            console.error(`No translation in DB for key: ${key}`);
          }
        }),
        map((text) => {
          return text || 'No translations available';
        }),
        map((text) => {
          for (let i = 0; i < args.length; i++) {
            if (!!args[i]) {
              text = text.replace('%%%', args[i]!!.toString());
            }
          }
          return text;
        }),
        tap((translation) => cachedKeys.set(cacheKey, translation))
      );
  }
}
