import {ActionReducerMap, MetaReducer} from '@ngrx/store';
import {environment} from '../../environments/environment';
import {storeFreeze} from 'ngrx-store-freeze';

export interface State {}

export const reducers: ActionReducerMap<State> = {};

export const metaReducers: MetaReducer[] = !environment.production
  ? [storeFreeze]
  : [];
