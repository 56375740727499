import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslationsKeys} from '../../core/api/models/translation.model';
import {I18nPipe} from '../../pipes/i18n.pipe';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [CommonModule, I18nPipe],
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() public title: TranslationsKeys | null = null;
  @Input() public translatedText: string | null = '';
}
