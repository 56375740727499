import {Injectable} from '@angular/core';
import {NativeDateAdapter} from '@angular/material/core';

@Injectable()
export class CalendarFromMondayDateAdapter extends NativeDateAdapter {
  public override getFirstDayOfWeek(): number {
    return 1;
  }

  public override getDayOfWeekNames(_: 'long' | 'short' | 'narrow') {
    return super
      .getDayOfWeekNames('long')
      .map((day) => day.substring(0, 2).toUpperCase());
  }
}
