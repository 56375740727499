import {createSelector} from '@ngrx/store';
import {coreFeature} from './core.reducer';

export const selectCalendarStartingDate = createSelector(
  coreFeature.selectClosestAppointmentDate,
  (startingDate) => {
    if (!!startingDate) {
      return new Date(startingDate);
    } else {
      return null;
    }
  }
);

export const selectAvailableAppointmentTime = createSelector(
  coreFeature.selectAppointmentSuggestions,
  coreFeature.selectSelectedAppointmentDayInCalendar,
  (suggestions, selectedDay) => {
    const day = selectedDay.getDate();
    const month = selectedDay.getMonth() + 1;
    const year = selectedDay.getFullYear();

    return suggestions.filter(
      (item) => item.day === day && item.month === month && item.year === year
    );
  }
);
