import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {I18nPipe} from '../../pipes/i18n.pipe';

@Component({
  selector: 'app-error-page',
  standalone: true,
  imports: [CommonModule, MatIconModule, I18nPipe],
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {}
