import {Injectable} from '@angular/core';
import {catchError, distinctUntilChanged, filter, of, switchMap} from 'rxjs';
import {
  appInit,
  changeCalendarMonth,
  createAppointmentFailed,
  createAppointmentRequested,
  createAppointmentSuccess,
  fetchAppointmentSuggestionsFailed,
  fetchAppointmentSuggestionsRequested,
  fetchAppointmentSuggestionsSuccess,
  fetchClosestAppointmentsFailed,
  fetchClosestAppointmentsRequested,
  fetchClosestAppointmentsSuccess,
  fetchUnallocatedSlotsFailed,
  fetchUnallocatedSlotsRequested,
  fetchUnallocatedSlotsSuccess,
  fetchUserConfigurationFailed,
  fetchUserConfigurationRequested,
  fetchUserConfigurationSuccess,
  getConfigurationId,
  setApplicationLanguage,
  setAvailableDaysInMonth
} from './core.actions';
import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';
import {CalendarApiService} from '../../api';
import {map} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {buildFullDate} from '../../../functions/add-minutes-to-date.func';
import {selectCalendarStartingDate} from './core.selectors';
import {coreFeature} from './core.reducer';
import {convertAppointmentSuggestionsToDaySuggestions} from '../../../functions/convert-appointment-suggestions-to-day-suggestions.func';
import {getErrorBasedOnStatusCode} from '../../../functions/error-mapper.func';
import {ActivatedRoute, Router} from '@angular/router';
import {fetchTranslationsRequested} from '../translations/translations.actions';
import {Stages} from '../../constants/stages.consts';
import {HttpErrorResponse} from '@angular/common/http';
import {SupportedLanguagesEnum} from '../../../models/supported-languages.enum';
import {getDaysInMonth} from '../../../functions/get-days-in-month.func';
import {generateCalendarAvailabilityFunc} from '../../../functions/generate-calendar-availability.func';

@Injectable()
export class CoreEffects {
  // FETCH CONFIGURATION BASED ON CALENDAR NAME
  public onFetchUserConfigurationRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchUserConfigurationRequested),
      switchMap((action) =>
        this.calendarApiService.getUserDetails(action.id).pipe(
          map((response) => fetchUserConfigurationSuccess({response})),
          catchError((error) => of(fetchUserConfigurationFailed({error})))
        )
      )
    );
  });

  public onFetchUserConfigurationSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchUserConfigurationSuccess),
      map(({response}) => {
        return fetchClosestAppointmentsRequested({
          appointmentCalendarId: response.calendarId,
          duration: response.appointmentDuration
        });
      })
    );
  });

  public onFetchUserConfigurationFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fetchUserConfigurationFailed),
        switchMap(() => {
          return this.router.navigate(['/error']);
        })
      );
    },
    {dispatch: false}
  );

  // FETCH UNALLOCATED SLOTS
  public onFetchUnallocatedSlotsRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchUnallocatedSlotsRequested),
      switchMap(({appointmentCalendarId, startDate, endDate}) =>
        this.calendarApiService
          .getUnallocatedSlots(appointmentCalendarId, startDate, endDate)
          .pipe(
            map((unallocatedSlots) =>
              fetchUnallocatedSlotsSuccess({unallocatedSlots})
            ),
            catchError((error) => of(fetchUnallocatedSlotsFailed({error})))
          )
      )
    );
  });

  public onFetchUnallocatedSlotsSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchUnallocatedSlotsSuccess),
      map((action) => {
        const availableAppointmentDays = generateCalendarAvailabilityFunc(
          action.unallocatedSlots
        );
        return setAvailableDaysInMonth({
          days: availableAppointmentDays.days,
          year: availableAppointmentDays.year,
          month: availableAppointmentDays.month
        });
      })
    );
  });

  // FETCH CLOSEST APPOINTMENT
  public onFetchClosestAppointmentsRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchClosestAppointmentsRequested),
      switchMap(({appointmentCalendarId, duration}) =>
        this.calendarApiService
          .getClosestAppointments(appointmentCalendarId, duration)
          .pipe(
            map((response) => fetchClosestAppointmentsSuccess({response})),
            catchError((error) => of(fetchClosestAppointmentsFailed({error})))
          )
      )
    );
  });
  public onFetchClosestAppointmentsSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchClosestAppointmentsSuccess),
      concatLatestFrom(() => [
        this.store.select(selectCalendarStartingDate),
        this.store.select(coreFeature.selectClientConfiguration)
      ]),
      map(([_, startingDate, clientConfiguration]) => {
        const startingMonth = startingDate!!.getMonth();
        const startingYear = startingDate!!.getFullYear();

        return fetchUnallocatedSlotsRequested({
          appointmentCalendarId: clientConfiguration!!.calendarId,
          endDate: buildFullDate(
            new Date(
              startingYear,
              startingMonth,
              getDaysInMonth(startingYear, startingMonth + 1)
            ).resetToEndOfTheDay(),
            true
          ),
          startDate: buildFullDate(new Date(startingDate!!), true)
        });
      })
    );
  });

  // FETCH APPOINTMENT SUGGESTIONS
  public onFetchAppointmentSuggestionsRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchAppointmentSuggestionsRequested),
      switchMap((action) =>
        this.calendarApiService
          .getAppointmentSuggestions(
            action.appointmentCalendarId,
            action.startDate,
            action.endDate,
            action.duration
          )
          .pipe(
            map((response) =>
              fetchAppointmentSuggestionsSuccess({
                response:
                  convertAppointmentSuggestionsToDaySuggestions(response)
              })
            ),
            catchError((error) =>
              of(fetchAppointmentSuggestionsFailed({error}))
            )
          )
      )
    );
  });

  // CHANGE DATE IN CALENDAR
  public onNewDateIsSelectedInCalendar$ = createEffect(() => {
    return this.store
      .select(coreFeature.selectSelectedAppointmentDayInCalendar)
      .pipe(
        distinctUntilChanged(
          (prev, curr) => prev.toISOString() === curr.toISOString()
        ),
        concatLatestFrom(() => [
          this.store.select(coreFeature.selectClientConfiguration)
        ]),
        filter(([_, configuration]) => !!configuration),
        map(([date, configuration]) => {
          return fetchAppointmentSuggestionsRequested({
            appointmentCalendarId: configuration!!.calendarId,
            duration: configuration!!.appointmentDuration,
            endDate: buildFullDate(date!!.resetToEndOfTheDay()),
            startDate: buildFullDate(date!!.resetToDayStart())
          });
        })
      );
  });
  public onFetchAppointmentsForSelectedDate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(changeCalendarMonth),
      concatLatestFrom(() => [
        this.store.select(coreFeature.selectClientConfiguration)
      ]),
      map(([action, clientConfiguration]) => {
        const {startDate, endDate} = this.generateDatesForUnallocatedSlots(
          action.date
        );
        return fetchUnallocatedSlotsRequested({
          appointmentCalendarId: clientConfiguration!!.calendarId,
          endDate,
          startDate
        });
      })
    );
  });
  public onCreateAppointmentRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createAppointmentRequested),
      concatLatestFrom(() => [
        this.store.select(coreFeature.selectClientConfiguration)
      ]),
      switchMap(([action, clientConfiguration]) =>
        this.calendarApiService
          .createNewAppointment(
            clientConfiguration!!.calendarId,
            action.appointmentModel
          )
          .pipe(
            map((response) => {
              return createAppointmentSuccess({
                response
              });
            }),
            catchError((error: HttpErrorResponse) =>
              of(
                createAppointmentFailed({
                  error: getErrorBasedOnStatusCode(error.error?.statusCode)
                })
              )
            )
          )
      )
    );
  });
  public onAppInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(appInit),
      concatLatestFrom(() => [this.store.select(coreFeature.selectLanguage)]),
      switchMap(([_, language]) => [
        fetchTranslationsRequested({
          language: language === SupportedLanguagesEnum.EN ? 1 : 2
        }),
        getConfigurationId()
      ])
    );
  });
  public onSetApplicationLanguage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setApplicationLanguage),
      concatLatestFrom(() => [this.store.select(coreFeature.selectLanguage)]),
      switchMap(([_, language]) => [
        fetchTranslationsRequested({
          language: language === SupportedLanguagesEnum.EN ? 1 : 2
        })
      ])
    );
  });
  public onNavigateToStep$ = createEffect(
    () => {
      return this.store.select(coreFeature.selectCurrentStage).pipe(
        filter((stage) => stage !== Stages.SELECT_DATE_AND_TIME),
        switchMap((stage) => {
          return this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {stage},
            queryParamsHandling: 'merge'
          });
        })
      );
    },
    {dispatch: false}
  );
  public onGetConfigurationId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getConfigurationId),
      map(() => {
        const pathName = window.location.pathname;
        return pathName.substring(1, pathName.length);
      }),
      map((id) => fetchUserConfigurationRequested({id}))
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly calendarApiService: CalendarApiService
  ) {}

  private generateDatesForUnallocatedSlots(date: Date): {
    startDate: string;
    endDate: string;
  } {
    const startingMonth = date.getMonth();
    const startingYear = date.getFullYear();
    const currentDate = new Date();

    let startDate = buildFullDate(
      new Date(date!!.getFullYear(), date!!.getMonth(), 1),
      true
    );

    if (
      startingMonth === currentDate.getMonth() &&
      startingYear === currentDate.getFullYear()
    ) {
      startDate = buildFullDate(
        new Date(
          date.getFullYear(),
          date.getMonth(),
          currentDate.getDate()
        ).resetToDayStart(),
        true
      );
    }

    const endDate = buildFullDate(
      new Date(
        startingYear,
        startingMonth,
        getDaysInMonth(startingYear, startingMonth + 1)
      ).resetToEndOfTheDay(),
      true
    );

    return {
      startDate,
      endDate
    };
  }
}
