<form *ngIf="formGroup" [formGroup]="formGroup" class="form-container">
  <mat-label>{{ label | i18n | async }}</mat-label>
  <mat-form-field appearance="outline" floatLabel="always">
    <textarea
      [formControlName]="controlKey"
      cdkAutosizeMinRows="3"
      cdkTextareaAutosize
      matInput
    ></textarea>
  </mat-form-field>
  <mat-error>{{
    formGroup.get(controlKey)?.errors
      | formGroupFieldErrors : errorMapper
      | async
  }}</mat-error>
</form>
