import {
  PhoneNumber,
  PhoneNumberFormat,
  PhoneNumberUtil
} from 'google-libphonenumber';

export function getNumberFormatted(
  phoneNumber: string,
  regionCode: string = 'DE'
): string {
  try {
    const phoneNumberParsed = getPhoneNumberParsed(phoneNumber, regionCode);

    if (!phoneNumberParsed) {
      return phoneNumber;
    }

    const validNumber =
      PhoneNumberUtil.getInstance().isValidNumber(phoneNumberParsed);

    if (validNumber) {
      return PhoneNumberUtil.getInstance().format(
        phoneNumberParsed,
        PhoneNumberFormat.INTERNATIONAL
      );
    }
  } catch (e) {
    return phoneNumber;
  }

  return phoneNumber;
}

function getPhoneNumberParsed(
  phoneNumber: string,
  regionCode: string
): PhoneNumber | null {
  try {
    return PhoneNumberUtil.getInstance().parseAndKeepRawInput(
      phoneNumber,
      regionCode
    );
  } catch (error) {
    return null;
  }
}
