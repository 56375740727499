import {Injector, Pipe, PipeTransform} from '@angular/core';
import {
  CustomValueFormatters,
  editableLabelCustomFormattersModel
} from './models/editable-label-custom-formatters.model';

@Pipe({
  name: 'customFormatter',
  standalone: true
})
export class EditableLabelCustomFormatterPipe implements PipeTransform {
  constructor(private readonly injector: Injector) {}

  public transform(
    formValue: string,
    formatterType?: CustomValueFormatters | null
  ): string {
    const selectedFormatter = editableLabelCustomFormattersModel.find(
      (formatter) => formatter.name === formatterType
    );
    if (!selectedFormatter || !selectedFormatter.value) {
      return formValue;
    }
    return this.injector.get(selectedFormatter.value)?.transform(formValue);
  }
}
