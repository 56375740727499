import {createFeature, createReducer, on} from '@ngrx/store';
import {
  fetchTranslationsFailed,
  fetchTranslationsRequested,
  fetchTranslationsSuccess
} from './translations.actions';
import {TranslationsState} from './translations.model';

export const initialState: TranslationsState = {
  translationsLoading: true,
  translations: null
};

export const translationsFeature = createFeature({
  name: 'translations',
  reducer: createReducer(
    initialState,
    on(
      fetchTranslationsRequested,
      (state): TranslationsState => ({
        ...state,
        ...{translationsLoading: true}
      })
    ),
    on(fetchTranslationsSuccess, (state, action): TranslationsState => {
      return {
        ...state,
        ...{
          translationsLoading: false,
          translations: action.response
        }
      };
    }),
    on(
      fetchTranslationsFailed,
      (state): TranslationsState => ({
        ...state,
        ...{translationsLoading: false}
      })
    )
  )
});
