<div class="confirm-container">
  <div class="confirm-container__icon">
    <img alt="confirm" src="assets/confirm.svg" />
  </div>

  <h1 class="confirm-container__title">{{
    'EAB.COMMON.BOOKING_CONFIRMED' | i18n | async
  }}</h1>

  <div class="confirm-container__sub-title">
    {{
      'EAB.COMMON.CONFIRMATION_SEND'
        | i18n : (userAppointmentInformation$ | async)?.email
        | async
    }}
  </div>

  <app-flat-card class="confirmation-details-container">
    <p class="confirmation-details-container__title">
      {{ (customerConfiguration$ | async)?.appointmentName }}
    </p>

    <div class="confirmation-details-container__section">
      <div>
        <mat-icon>event_available</mat-icon>
        <span>{{ 'EAB.COMMON.WHEN' | i18n | async }}</span>
      </div>
      <app-appointment-time-range></app-appointment-time-range>
    </div>

    <div
      *ngIf="
        (customerConfiguration$ | async)
          ?.appointmentLocation as customerConfiguration
      "
      class="confirmation-details-container__section"
    >
      <div>
        <mat-icon>location_on</mat-icon>
        <span>{{ 'EAB.COMMON.ADDRESS' | i18n | async }}</span>
      </div>
      <div>
        {{ customerConfiguration }}
      </div>
    </div>

    <div class="confirmation-details-container__section">
      <div>
        <mat-icon>error_outline</mat-icon>
        <span>{{ 'EAB.COMMON.INFORMATION' | i18n | async }}</span>
      </div>
      <div>
        {{ (customerConfiguration$ | async)?.infoToCaller }}
      </div>
    </div>
  </app-flat-card>

  <app-calendar-bar></app-calendar-bar>
</div>
