<ng-container [ngSwitch]="buttonType">
  <button
    (click)="mouseClick()"
    *ngSwitchCase="'basic'"
    [disabled]="loading || disabled"
    color="dark"
    mat-button
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>

  <button
    (click)="mouseClick()"
    *ngSwitchCase="'stroke'"
    [disabled]="loading || disabled"
    mat-stroked-button
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
</ng-container>

<ng-template #content>
  <div class="button__body">
    <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
    <span>{{ label | i18n | async }}</span>
    <mat-spinner *ngIf="loading" [diameter]="30"></mat-spinner>
  </div>
</ng-template>
