{{ (selectedHour$ | async)?.startHour }}:{{
  (selectedHour$ | async)?.startMinutes
}}
-
{{ (selectedHour$ | async)?.endHour }}:{{
  (selectedHour$ | async)?.endMinutes
}}
({{ (configuration$ | async)?.appointmentDuration }}
{{ 'EAB.COMMON.MINUTES' | i18n | async }})

<br />

{{ (selectedHour$ | async)?.orginalStartTime | getDayName : true | async }}
,
{{ (selectedHour$ | async)?.orginalStartTime | getDate }}
{{ (selectedHour$ | async)?.orginalStartTime | getMonthName : true | async }}
{{ (selectedHour$ | async)?.orginalStartTime | getYear }}
