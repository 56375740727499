import {Component, Input, OnInit} from '@angular/core';
import {TranslationsKeys} from '../../core/api/models/translation.model';
import {InputErrorMapperInterface} from '../input/models/input-error-map.interface';
import {ControlContainer, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {I18nPipe} from '../../pipes/i18n.pipe';
import {FormGroupFieldErrorsPipe} from '../../pipes/form-group-field-errors.pipe';

@Component({
  selector: 'app-text-area',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    I18nPipe,
    ReactiveFormsModule,
    FormGroupFieldErrorsPipe
  ],
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent implements OnInit {
  @Input() public label!: TranslationsKeys;
  @Input() public controlKey!: string;
  @Input() public errorMapper: InputErrorMapperInterface[] = [];

  public formGroup!: FormGroup;

  constructor(private readonly controlContainer: ControlContainer) {}

  public ngOnInit(): void {
    this.formGroup = this.controlContainer.control as FormGroup;
    if (!Object.keys(this.formGroup.controls).includes(this.controlKey)) {
      throw new Error(`Form does not contain key ${this.controlKey}`);
    }
  }
}
