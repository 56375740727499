import {createAction, props} from '@ngrx/store';
import {GetTranslation} from '../../api/models/get-translation.model';
import {ApiTranslation} from '../../api/models/translation.model';

export const fetchTranslationsRequested = createAction(
  '[TRANSLATIONS] Fetch translations requested',
  (translationsParameters: GetTranslation) => ({
    payload: {translationsParameters}
  })
);
export const fetchTranslationsSuccess = createAction(
  '[TRANSLATIONS] Fetch translations success',
  props<{response: ApiTranslation}>()
);
export const fetchTranslationsFailed = createAction(
  '[TRANSLATIONS] Fetch translations failed',
  props<{error: unknown}>()
);
