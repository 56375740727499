export enum FormFieldErrorEnum {
  REQUIRED = 'REQUIRED',
  EXISTS = 'EXISTS',
  EMPTY = 'EMPTY',
  LONG = 'LONG',
  SHORT = 'SHORT',
  INVALID = 'INVALID',
  ONE_NUMBER = 'ONE_NUMBER',
  ONE_LOWERCASE = 'ONE_LOWERCASE',
  ONE_UPPERCASE = 'ONE_UPPERCASE',
  ONE_SPECIAL_CHARACTER = 'ONE_SPECIAL_CHARACTER',
  SHOULD_MATCH = 'SHOULD_MATCH',
  PATTERN = 'PATTERN',
  REQUIRE = 'required',
  EMAIL = 'EMAIL',
  MAXLENGTH = 'MAXLENGTH',
  WRONGNUMBER = 'validator_wrong_number'
}
