import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {translationsFeature} from './translations.reducer';
import {TranslationsEffects} from './translations.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(translationsFeature),
    EffectsModule.forFeature([TranslationsEffects])
  ],
  exports: [StoreModule, EffectsModule]
})
export class TranslationsEntityModule {}
