import {Injectable} from '@angular/core';
import {
  CalendarEventDate,
  CalendarEventModel
} from '../models/calendar-event.model';
import {addMinutesToStringDate} from '../functions/add-minutes-to-date.func';

@Injectable({
  providedIn: 'root'
})
export class Office365CalendarService implements CalendarEventModel {
  public generateUrl({
    title,
    startDate,
    endDate,
    location,
    details
  }: CalendarEventDate): string {
    if (!title || !startDate || !endDate) {
      throw new Error(
        'Required parameters not delivered: title, startDate, endDate'
      );
    }
    const startTime = this.adjustOffset(startDate);
    const endTime = this.adjustOffset(endDate);
    const params = [
      {param: 'subject', value: title},
      {param: 'body', value: details},
      {param: 'location', value: location},
      {
        param: 'startdt',
        value: startTime
      },
      {
        param: 'enddt',
        value: endTime
      },
      {param: 'allday', value: false}
    ]
      .map((x) => this.mergeParameters(x.param, x.value))
      .filter((x) => !!x)
      .join('&');

    return `https://outlook.office.com/calendar/0/deeplink/compose?path=/calendar/action/compose&rru=addevent&${params}`;
  }

  private adjustOffset(date: string): string {
    const timezoneOffset = new Date(date).getTimezoneOffset();
    const utcTime = addMinutesToStringDate(date, timezoneOffset);
    return this.adjustDateString(new Date(utcTime).toISOString());
  }

  private adjustDateString(date: string): string {
    return date.replace('.000Z', 'Z');
  }

  private mergeParameters(param: string, value: string | boolean | undefined) {
    if (!!value) {
      return `${param}=${value}`;
    }
    return '';
  }
}
