import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Store} from '@ngrx/store';
import {I18nPipe} from '../../pipes/i18n.pipe';
import {coreFeature} from '../../core/store/core/core.reducer';

@Component({
  selector: 'app-company-info-card',
  standalone: true,
  imports: [CommonModule, I18nPipe],
  templateUrl: './company-info-card.component.html',
  styleUrls: ['./company-info-card.component.scss']
})
export class CompanyInfoCardComponent {
  public readonly customerConfiguration$ = this.store.select(
    coreFeature.selectClientConfiguration
  );

  constructor(private readonly store: Store) {}
}
