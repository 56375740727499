import {Injectable} from '@angular/core';
import {Adapter} from '../api/adapters/adapter';
import {buildFullDate} from '../../functions/add-minutes-to-date.func';
import {ApiResponseModel} from '../api/models/api-response.model';
import {UnallocatedSlotApiResponseModel} from '../api/models/unallocated-slots.api.model';

export class ClosestAppointmentModel {
  constructor(public closestAppointmentDate: string | null) {}
}

@Injectable({
  providedIn: 'root'
})
export class ClosestAppointmentAdapter
  implements Adapter<ClosestAppointmentModel>
{
  public adapt(
    item: ApiResponseModel<UnallocatedSlotApiResponseModel[]>
  ): ClosestAppointmentModel {
    let closestDate = (item?.result || [])
      .map((x) => x.startTime)
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    if (closestDate.length <= 0) {
      closestDate = [buildFullDate(new Date(), true)];
    }

    return new ClosestAppointmentModel(closestDate[0]);
  }
}
