import {createSelector} from '@ngrx/store';
import {translationsFeature} from './translations.reducer';
import {ApiTranslation} from '../../api/models/translation.model';

export const selectTranslation = (
  localKey: string,
  categoryKey: string,
  globalKey: string
) =>
  createSelector(
    translationsFeature.selectTranslations,
    (translations: ApiTranslation | null) => {
      if (
        !!translations &&
        !!translations[globalKey] &&
        !!translations[globalKey][categoryKey] &&
        !!translations[globalKey][categoryKey][localKey] &&
        !!translations[globalKey][categoryKey][localKey]?.translation
      ) {
        return translations[globalKey][categoryKey][localKey].translation;
      }
      console.warn(
        `Cannot find translation with globalKey: ${globalKey} categoryKey: ${categoryKey} localKey: ${localKey}`
      );
      return '';
    }
  );
