import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslationsKeys} from '../../core/api/models/translation.model';
import {I18nPipe} from '../../pipes/i18n.pipe';

@Component({
  selector: 'app-error-block',
  standalone: true,
  imports: [CommonModule, I18nPipe],
  templateUrl: './error-block.component.html',
  styleUrls: ['./error-block.component.scss']
})
export class ErrorBlockComponent {
  @Input() public errorLabel!: TranslationsKeys | null;
}
