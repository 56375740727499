import {
  Component,
  HostListener,
  Input,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import {ControlContainer, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {MatInput, MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {I18nPipe} from '../../pipes/i18n.pipe';
import {TranslationsKeys} from '../../core/api/models/translation.model';
import {FormGroupFieldErrorsPipe} from '../../pipes/form-group-field-errors.pipe';
import {InputErrorMapperInterface} from './models/input-error-map.interface';
import {CheckIfFormFieldRequiredPipe} from '../../pipes/check-if-field-required.pipe';
import {EditableLabelCustomFormatterPipe} from './editable-label-custom-formatter.pipe';
import {CustomValueFormatters} from './models/editable-label-custom-formatters.model';
import {FormatNumberPipe} from '../../pipes/format-number.pipe';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    I18nPipe,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    FormGroupFieldErrorsPipe,
    CheckIfFormFieldRequiredPipe,
    EditableLabelCustomFormatterPipe,
    FormatNumberPipe
  ],
  viewProviders: [I18nPipe, EditableLabelCustomFormatterPipe, FormatNumberPipe]
})
export class InputComponent implements OnInit {
  @Input() public label!: TranslationsKeys;
  @Input() public controlKey!: string;
  @Input() public nativeType!: string;
  @Input() public autocomplete = false;
  @Input() public autoFocusInput = false;
  @Input() public errorMapper: InputErrorMapperInterface[] = [];
  @Input() public valueFormatter: CustomValueFormatters | null = null;
  @ViewChildren(MatInput) public input!: QueryList<HTMLInputElement>;
  public formGroup!: FormGroup;

  constructor(
    private readonly controlContainer: ControlContainer,
    private readonly customFormatter: EditableLabelCustomFormatterPipe
  ) {}

  @HostListener('keyup', ['$event'])
  public onKeyup(): void {
    const controlValue = this.formGroup?.get(this.controlKey)?.value;
    if (controlValue && !!this.valueFormatter) {
      const transformedValue = this.customFormatter.transform(
        controlValue,
        this.valueFormatter
      );
      this.formGroup?.get(this.controlKey)?.setValue(transformedValue);
    }
  }

  public ngOnInit(): void {
    this.formGroup = this.controlContainer.control as FormGroup;
    if (!Object.keys(this.formGroup.controls).includes(this.controlKey)) {
      throw new Error(`Form does not contain key ${this.controlKey}`);
    }
  }
}
