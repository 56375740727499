import {createAction, props} from '@ngrx/store';
import {CalendarConfigurationModel} from '../../models/calendar-configuration.model';
import {ClosestAppointmentModel} from '../../models/appointment-suggestions.model';
import {
  AppointmentSuggestionHourViewModel,
  AppointmentSuggestionViewModel
} from '../../../models/appointment-suggestion-view-model.model';
import {CreateAppointmentApiModel} from '../../api';
import {Stages} from '../../constants/stages.consts';
import {BookedAppointmentModel} from '../../api/models/appointment-booked.model';
import {TranslationsKeys} from '../../api/models/translation.model';
import {SupportedLanguagesEnum} from '../../../models/supported-languages.enum';
import {CalendarUnallocatedSlotsModel} from '../../models/calendar-unallocated-slots.adapter.model';

export const appInit = createAction('[CORE] Application init');

export const getConfigurationId = createAction('[CORE] Get configuration Id');

export const fetchUserConfigurationRequested = createAction(
  '[CORE] Fetch user configuration requested',
  props<{
    id: string;
  }>()
);

export const fetchUserConfigurationSuccess = createAction(
  '[CORE] Fetch user configuration success',
  props<{
    response: CalendarConfigurationModel;
  }>()
);

export const fetchUserConfigurationFailed = createAction(
  '[CORE] Fetch user configuration failed',
  props<{
    error: any;
  }>()
);

export const fetchClosestAppointmentsRequested = createAction(
  '[CORE] Fetch closest appointments requested',
  props<{
    appointmentCalendarId: number;
    duration: number;
  }>()
);

export const fetchClosestAppointmentsSuccess = createAction(
  '[CORE] Fetch closest appointments success',
  props<{
    response: ClosestAppointmentModel;
  }>()
);

export const fetchClosestAppointmentsFailed = createAction(
  '[CORE] Fetch closest appointments failed',
  props<{
    error: any;
  }>()
);

export const changeCalendarMonth = createAction(
  '[CORE] Fetch appointments for selected date',
  props<{
    date: Date;
  }>()
);

export const fetchUnallocatedSlotsRequested = createAction(
  '[CORE] Fetch unallocated slots requested',
  props<{
    appointmentCalendarId: number;
    startDate: string;
    endDate: string;
  }>()
);

export const fetchUnallocatedSlotsSuccess = createAction(
  '[CORE] Fetch unallocated slots success',
  props<{
    unallocatedSlots: CalendarUnallocatedSlotsModel[];
  }>()
);

export const fetchUnallocatedSlotsFailed = createAction(
  '[CORE] Fetch unallocated slots failed',
  props<{
    error: any;
  }>()
);

export const setAvailableDaysInMonth = createAction(
  '[CORE] Set available days in month',
  props<{
    days: number[];
    year: number;
    month: number;
  }>()
);

export const fetchAppointmentSuggestionsRequested = createAction(
  '[CORE] Fetch appointment suggestion requested',
  props<{
    appointmentCalendarId: number;
    duration: number;
    startDate: string;
    endDate: string;
  }>()
);

export const fetchAppointmentSuggestionsSuccess = createAction(
  '[CORE] Fetch appointment suggestion success',
  props<{
    response: AppointmentSuggestionViewModel[];
  }>()
);

export const fetchAppointmentSuggestionsFailed = createAction(
  '[CORE] Fetch appointment suggestion failed',
  props<{
    error: any;
  }>()
);

export const userSelectedAppointmentDay = createAction(
  '[CORE] User selected appointment day',
  props<{
    date: Date;
  }>()
);

export const userSelectedAppointment = createAction(
  '[CORE] User selected appointment',
  props<{
    selectedAppointment: AppointmentSuggestionHourViewModel;
  }>()
);

export const createAppointmentRequested = createAction(
  '[CORE] Create appointment requested',
  props<{
    appointmentModel: CreateAppointmentApiModel;
  }>()
);

export const createAppointmentSuccess = createAction(
  '[CORE] Create appointment success',
  props<{
    response: BookedAppointmentModel;
  }>()
);

export const createAppointmentFailed = createAction(
  '[CORE] Create appointment failed',
  props<{
    error: TranslationsKeys;
  }>()
);

export const navigateToStep = createAction(
  '[CORE] Navigate to step',
  props<{
    step: Stages;
  }>()
);

export const setApplicationLanguage = createAction(
  '[CORE] Set application language',
  props<{
    language: SupportedLanguagesEnum;
  }>()
);
