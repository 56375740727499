import {Pipe, PipeTransform} from '@angular/core';
import {map, Observable, of} from 'rxjs';
import {I18nPipe} from './i18n.pipe';
import {TranslationsKeys} from '../core/api/models/translation.model';
import {monthNames} from '../core/constants/month-names.const';

@Pipe({
  name: 'getMonthName',
  standalone: true
})
export class GetMonthNamePipe implements PipeTransform {
  constructor(private readonly i18n: I18nPipe) {}

  public transform(
    value: Date | string | undefined,
    longName: boolean = false
  ): Observable<string> {
    if (!value) {
      return of('');
    }

    value = new Date(value);

    return this.i18n
      .transform(monthNames[value.getMonth()] as TranslationsKeys)
      .pipe(
        map((text) => (longName ? text : (text as string).substring(0, 3)))
      );
  }
}
