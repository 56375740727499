<div class="calendar-info d-flex">
  <img alt="secretary" src="assets/secretary_homepage.png" />
  <h1
    *ngIf="customerConfiguration$ | async as appointmentConfiguration"
    class="info-text"
    >{{
      'EAB.COMMON.WELCOME_MESSAGE'
        | i18n : appointmentConfiguration?.calendarOwnerFullName
        | async
    }}
  </h1>
</div>
