import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {CommonModule, LocationStrategy} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MatStepper, MatStepperModule} from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {SelectTimeComponent} from '../select-time/select-time.component';
import {ProvideAppointmentInformationComponent} from '../provide-appointment-information/provide-appointment-information.component';
import {ConfirmAppointmentTimeComponent} from '../confirm-appointment-time/confirm-appointment-time.component';
import {I18nPipe} from '../../pipes/i18n.pipe';
import {delay, filter, Subscription} from 'rxjs';
import {ErrorPageComponent} from '../error-page/error-page.component';
import {SpinnerIndicatorComponent} from '../../shared-components/spinner-indicator/spinner-indicator.component';
import {map} from 'rxjs/operators';
import {MatIconModule} from '@angular/material/icon';
import {Store} from '@ngrx/store';
import {navigateToStep} from '../../core/store/core/core.actions';
import {coreFeature} from '../../core/store/core/core.reducer';
import {Stages} from '../../core/constants/stages.consts';

@Component({
  selector: 'app-booking-manager',
  standalone: true,
  imports: [
    CommonModule,
    ConfirmAppointmentTimeComponent,
    MatStepperModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    SelectTimeComponent,
    ProvideAppointmentInformationComponent,
    I18nPipe,
    ErrorPageComponent,
    SpinnerIndicatorComponent,
    MatIconModule
  ],
  viewProviders: [I18nPipe],
  templateUrl: './booking-manager.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookingManagerComponent implements OnInit, OnDestroy {
  @ViewChild(SelectTimeComponent)
  public selectTimeComponent!: SelectTimeComponent;
  @ViewChild(ProvideAppointmentInformationComponent)
  public provideAppointmentInformationComponent!: ProvideAppointmentInformationComponent;

  public isCalendarLoading$ = this.store
    .select(coreFeature.selectClosestAppointmentLoading)
    .pipe(delay(10));
  public appointmentTimeSelected$ = this.store
    .select(coreFeature.selectSelectedAppointment)
    .pipe(map((appointment) => !!appointment));

  public appointmentSaved$ = this.store.select(
    coreFeature.selectAppointmentSaved
  );
  @ViewChild('stepper') private readonly stepper!: MatStepper;

  private readonly subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly location: LocationStrategy
  ) {
    this.location.onPopState(() => {
      history.pushState(null, '', window.location.href);
      const index = this.stepper.selectedIndex;
      if (index === Stages.CONFIRMATION) {
        window.location.href = `${window.location.origin}${window.location.pathname}`;
        return;
      }
      const previous: Stages = Object.values(Stages)[index - 1] as Stages;
      this.store.dispatch(navigateToStep({step: previous}));
    });
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.store
        .select(coreFeature.selectCurrentStage)
        .pipe(
          filter((_) => !!this.stepper),
          delay(1)
        )
        .subscribe((stage) => (this.stepper.selectedIndex = stage))
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
