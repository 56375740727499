<div class="form-container">
  <mat-label
    >{{ label | i18n | async }}
    {{ (formGroup | checkIfFormFieldRequired : controlKey : null) ? '*' : '' }}
  </mat-label>
  <form *ngIf="formGroup" [formGroup]="formGroup" class="app-input-form">
    <mat-form-field [appearance]="'outline'">
      <input
        [autocomplete]="autocomplete"
        [formControlName]="controlKey"
        [type]="nativeType"
        matInput
      />
      <mat-error>{{
        formGroup.get(controlKey)?.errors
          | formGroupFieldErrors : errorMapper
          | async
      }}</mat-error>
    </mat-form-field>
  </form>
</div>
