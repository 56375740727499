export const monthNames = [
  'EAB.COMMON.JANUARY',
  'EAB.COMMON.FEBRUARY',
  'EAB.COMMON.MARCH',
  'EAB.COMMON.APRIL',
  'EAB.COMMON.MAY',
  'EAB.COMMON.JUNE',
  'EAB.COMMON.JULY',
  'EAB.COMMON.AUGUST',
  'EAB.COMMON.SEPTEMBER',
  'EAB.COMMON.OCTOBER',
  'EAB.COMMON.NOVEMBER',
  'EAB.COMMON.DECEMBER'
];
