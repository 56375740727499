export {};

Date.prototype.resetToDayStart = function (this: Date): Date {
  this.setHours(0);
  this.setMinutes(0);
  this.setSeconds(0);
  return this;
};

Date.prototype.resetToEndOfTheDay = function (this: Date): Date {
  this.setHours(23);
  this.setMinutes(59);
  this.setSeconds(59);
  return this;
};
