import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCardModule} from '@angular/material/card';
import {MatNativeDateModule} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {I18nPipe} from '../../pipes/i18n.pipe';
import {MatSelectModule} from '@angular/material/select';
import {InputComponent} from '../../shared-components/input/input.component';
import {InputErrorMapperInterface} from '../../shared-components/input/models/input-error-map.interface';
import {FormFieldErrorEnum} from '../../models/form.model';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {BuildTranslationKeyPipe} from '../../pipes/build-translation-key-from-local.pipe';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {FormGroupFieldErrorsPipe} from '../../pipes/form-group-field-errors.pipe';
import {CreateAppointmentApiModel} from '../../core/api';
import {GetMonthNamePipe} from '../../pipes/get-month-name.pipe';
import {MinutesBetweenDatesPipe} from '../../pipes/minutes-between-dates.pipe';
import {GetYearPipe} from '../../pipes/get-year.pipe';
import {GetDatePipe} from '../../pipes/get-day.pipe';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {GetDayNamePipe} from '../../pipes/get-day-name.pipe';
import {CompanyInfoCardComponent} from '../../shared-components/company-info-card/company-info-card.component';
import {coreFeature} from '../../core/store/core/core.reducer';
import {Store} from '@ngrx/store';
import {AppointmentSuggestionHourViewModel} from '../../models/appointment-suggestion-view-model.model';
import {
  createAppointmentRequested,
  navigateToStep
} from '../../core/store/core/core.actions';
import {AppointmentTimeRangeComponent} from '../../shared-components/appointment-time-range/appointment-time-range.component';
import {Stages} from '../../core/constants/stages.consts';
import {TextAreaComponent} from '../../shared-components/text-area/text-area.component';
import {ButtonComponent} from '../../shared-components/button/button.component';
import {ErrorBlockComponent} from '../../shared-components/error-block/error-block.component';
import {FlatCardComponent} from '../../shared-components/flat-card/flat-card.component';
import {CardComponent} from '../../shared-components/card/card.component';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

@Component({
  selector: 'app-provide-appointment-information',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatCardModule,
    MatSelectModule,
    MatNativeDateModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    I18nPipe,
    InputComponent,
    BuildTranslationKeyPipe,
    ScrollingModule,
    FormGroupFieldErrorsPipe,
    GetMonthNamePipe,
    MinutesBetweenDatesPipe,
    GetYearPipe,
    GetDatePipe,
    GetDayNamePipe,
    CompanyInfoCardComponent,
    AppointmentTimeRangeComponent,
    TextAreaComponent,
    ButtonComponent,
    ErrorBlockComponent,
    FlatCardComponent,
    CardComponent
  ],
  viewProviders: [I18nPipe],
  templateUrl: './provide-appointment-information.component.html',
  styleUrls: ['./provide-appointment-information.component.scss']
})
export class ProvideAppointmentInformationComponent {
  public readonly customerConfiguration$ = this.store.select(
    coreFeature.selectClientConfiguration
  );
  public isAppointmentSaving$ = this.store.select(
    coreFeature.selectIsAppointmentSaving
  );

  public createNewAppointmentError$ = this.store.select(
    coreFeature.selectBookedAppointmentError
  );

  public ERROR_MAPPERS: InputErrorMapperInterface[] = [
    {
      key: FormFieldErrorEnum.REQUIRED,
      translation: 'EAB.COMMON.VALIDATOR_FIELD_REQUIRED'
    },
    {
      key: FormFieldErrorEnum.MAXLENGTH,
      translation: 'EAB.COMMON.VALIDATOR_MAX_LENGTH'
    },
    {
      key: FormFieldErrorEnum.EMAIL,
      translation: 'EAB.COMMON.VALIDATOR_EMAIL_NOT_VALID'
    },
    {
      key: FormFieldErrorEnum.WRONGNUMBER,
      translation: 'EAB.COMMON.VALIDATOR_WRONG_NUMBER'
    }
  ];
  public selectedHour$ = this.store.select(
    coreFeature.selectSelectedAppointment
  );
  public userInformation = this.formBuilder.group({
    firstName: new FormControl<string>('', [
      Validators.required,
      Validators.maxLength(60)
    ]),
    lastName: new FormControl<string>('', [
      Validators.required,
      Validators.maxLength(60)
    ]),
    companyName: new FormControl<string>('', [Validators.maxLength(60)]),
    email: new FormControl<string>('', [
      Validators.required,
      Validators.maxLength(60),
      Validators.email
    ]),
    phone: new FormControl<string>('+49', [
      Validators.required,
      this.phoneNumberValidator
    ]),
    note: new FormControl<string>('', [Validators.maxLength(500)])
  });

  constructor(
    private readonly store: Store,
    private readonly formBuilder: FormBuilder
  ) {}

  public save(selectedHour: AppointmentSuggestionHourViewModel) {
    if (this.userInformation.invalid) {
      this.userInformation.markAllAsTouched();
      return;
    }

    const formValues = this.userInformation.value as unknown as {
      firstName: string;
      lastName: string;
      companyName: string;
      email: string;
      phonePrefix: {countryPrefix: string};
      phone: string;
      note: string;
    };

    const appointmentModel: CreateAppointmentApiModel = {
      description: formValues.note,
      email: formValues.email,
      companyName: formValues.companyName,
      startDate: selectedHour!!.orginalStartTime,
      endDate: selectedHour!!.orginalEndTime,
      lastname: formValues.lastName,
      name: formValues.firstName,
      phoneNumber: formValues.phone
    };

    this.store.dispatch(createAppointmentRequested({appointmentModel}));
  }

  public navigateToPreviousPage() {
    this.store.dispatch(navigateToStep({step: Stages.SELECT_DATE_AND_TIME}));
  }

  private phoneNumberValidator(control: FormControl) {
    let validNumber = false;
    try {
      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(control.value);
      validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
    } catch (e) {}

    return validNumber ? null : {wrongNumber: {value: control.value}};
  }
}
