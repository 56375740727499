<app-spinner-indicator [loading]="isCalendarLoading$ | async">
  <div class="container">
    <div class="stepper">
      <mat-stepper
        #stepper
        [linear]="true"
        class="{{ 'last-edited-step-' + stepper.selectedIndex }} main-stepper"
        labelPosition="bottom"
      >
        <ng-template matStepperIcon="edit">
          <mat-icon>check</mat-icon>
        </ng-template>
        <mat-step
          [completed]="appointmentTimeSelected$ | async"
          [label]="('EAB.COMMON.STEP1' | i18n | async) || ''"
        >
          <app-select-time></app-select-time>
        </mat-step>
        <mat-step
          [completed]="appointmentSaved$ | async"
          [label]="('EAB.COMMON.STEP2' | i18n | async) || ''"
        >
          <app-provide-appointment-information></app-provide-appointment-information>
        </mat-step>
        <mat-step
          [editable]="false"
          [label]="('EAB.COMMON.STEP3' | i18n | async) || ''"
        >
          <app-confirm-appointment-time></app-confirm-appointment-time>
        </mat-step>
      </mat-stepper>
    </div>
  </div>
</app-spinner-indicator>
