import {Injectable} from '@angular/core';
import {Adapter} from '../api/adapters/adapter';
import {UnallocatedSlotsApiResponseModel} from '../api/models/unallocated-slots.api.model';

export class CalendarUnallocatedSlotsModel {
  constructor(public startTime: string, public endTime: string) {}
}

@Injectable({
  providedIn: 'root'
})
export class CalendarUnallocatedSlotsAdapter
  implements Adapter<CalendarUnallocatedSlotsModel[]>
{
  public adapt(
    item: UnallocatedSlotsApiResponseModel
  ): CalendarUnallocatedSlotsModel[] {
    return item.result.map((entity) => {
      return new CalendarUnallocatedSlotsModel(
        entity.startTime,
        entity.endTime
      );
    });
  }
}
