import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'getDate',
  standalone: true
})
export class GetDatePipe implements PipeTransform {
  public transform(value: Date | string | undefined): number {
    if (!value) {
      return 0;
    }

    value = new Date(value);

    return value.getDate();
  }
}
