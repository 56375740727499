<div>
  <app-company-info-card></app-company-info-card>

  <app-flat-card class="details-container">
    <p class="details-container__title">{{
      (customerConfiguration$ | async)?.appointmentName
    }}</p>

    <div
      *ngIf="
        (customerConfiguration$ | async)
          ?.appointmentLocation as customerConfiguration
      "
      class="details-container__section"
    >
      <mat-icon>location_on</mat-icon>
      <div>{{ customerConfiguration }}</div>
    </div>

    <div
      *ngIf="(customerConfiguration$ | async)?.infoToCaller"
      class="details-container__section details-container__section--orange"
    >
      <mat-icon>error_outline</mat-icon>
      <div>
        {{ (customerConfiguration$ | async)?.infoToCaller }}
      </div>
    </div>
  </app-flat-card>

  <mat-calendar
    (selectedChange)="onSelectedDateChanged($event)"
    *ngIf="minCalendarDate$ | async as minCalendarDate"
    [dateFilter]="dateFilter"
    [headerComponent]="calendarHeader"
    [minDate]="minCalendarDate"
    [selected]="selectedDate$ | async"
    class="select-time"
  ></mat-calendar>
</div>

<app-card
  [translatedText]="
    'EAB.COMMON.BOOK_MEETING_WITH_MINUTES'
      | i18n : (customerConfiguration$ | async)?.appointmentDuration
      | async
  "
  class="slots-card"
>
  <app-spinner-indicator [loading]="suggestedTimesLoading$ | async">
    <app-time-picker></app-time-picker>
  </app-spinner-indicator>
</app-card>
