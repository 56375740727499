import {Injectable} from '@angular/core';
import {AppointmentBookedApiResponseModel} from './appointment-booked-api-response.model';
import {Adapter} from '../adapters/adapter';

export class BookedAppointmentModel {
  constructor(
    public description: string,
    public endDateTime: string,
    public location: string,
    public startDateTime: string,
    public subject: string
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class BookedAppointmentAdapter
  implements Adapter<BookedAppointmentModel>
{
  public adapt(
    item: AppointmentBookedApiResponseModel
  ): BookedAppointmentModel {
    return new BookedAppointmentModel(
      item.result.description,
      item.result.endDateTime,
      item.result.location,
      item.result.startDateTime,
      item.result.subject
    );
  }
}
