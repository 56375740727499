import {createFeature, createReducer, on} from '@ngrx/store';
import {CoreState} from './core.model';
import {
  createAppointmentFailed,
  createAppointmentRequested,
  createAppointmentSuccess,
  fetchAppointmentSuggestionsFailed,
  fetchAppointmentSuggestionsRequested,
  fetchAppointmentSuggestionsSuccess,
  fetchClosestAppointmentsFailed,
  fetchClosestAppointmentsRequested,
  fetchClosestAppointmentsSuccess,
  fetchUnallocatedSlotsFailed,
  fetchUserConfigurationFailed,
  fetchUserConfigurationRequested,
  fetchUserConfigurationSuccess,
  navigateToStep,
  setApplicationLanguage,
  setAvailableDaysInMonth,
  userSelectedAppointment,
  userSelectedAppointmentDay
} from './core.actions';
import {tassign} from 'tassign';
import {Stages} from '../../constants/stages.consts';
import {SupportedLanguagesEnum} from '../../../models/supported-languages.enum';
import {buildFullDate} from '../../../functions/build-calendar-api-date.func';

export interface CoreModuleState {
  core: CoreState;
}

export const initialState: CoreState = {
  userConfigurationId: '',
  clientConfiguration: null,
  clientConfigurationLoading: true,
  closestAppointmentLoading: true,
  closestAppointmentDate: null,
  appointmentSuggestionsLoading: true,
  appointmentSuggestions: [],
  selectedAppointmentDayInCalendar: new Date(),
  selectedAppointment: null,
  currentStage: Stages.SELECT_DATE_AND_TIME,
  appointmentSaved: false,
  bookedAppointment: null,
  isAppointmentSaving: false,
  bookedAppointmentError: null,
  userAppointmentDate: null,
  language: SupportedLanguagesEnum.DE,
  availableDays: []
};

export const coreFeature = createFeature({
  name: 'core',
  reducer: createReducer(
    initialState,
    on(
      setAvailableDaysInMonth,
      (state, action): CoreState =>
        tassign<CoreState, Partial<CoreState>>(state, {
          availableDays: action.days
        })
    ),
    on(
      setApplicationLanguage,
      (state, action): CoreState =>
        tassign<CoreState, Partial<CoreState>>(state, {
          language: action.language
        })
    ),
    on(
      createAppointmentRequested,
      (state, action): CoreState =>
        tassign<CoreState, Partial<CoreState>>(state, {
          isAppointmentSaving: true,
          bookedAppointmentError: null,
          userAppointmentDate: action.appointmentModel
        })
    ),
    on(
      createAppointmentSuccess,
      (state, action): CoreState =>
        tassign<CoreState, Partial<CoreState>>(state, {
          isAppointmentSaving: false,
          bookedAppointment: action.response
        })
    ),
    on(
      createAppointmentFailed,
      (state, action): CoreState =>
        tassign<CoreState, Partial<CoreState>>(state, {
          isAppointmentSaving: false,
          bookedAppointmentError: action.error
        })
    ),
    on(
      navigateToStep,
      (state, action): CoreState =>
        tassign<CoreState, Partial<CoreState>>(state, {
          currentStage: action.step,
          bookedAppointmentError: null
        })
    ),
    on(
      userSelectedAppointmentDay,
      (state, action): CoreState =>
        tassign<CoreState, Partial<CoreState>>(state, {
          selectedAppointmentDayInCalendar: action.date
        })
    ),
    on(
      userSelectedAppointment,
      (state, action): CoreState =>
        tassign<CoreState, Partial<CoreState>>(state, {
          selectedAppointment: action.selectedAppointment,
          currentStage: Stages.FILL_USER_DATA
        })
    ),
    on(
      fetchUserConfigurationRequested,
      (state, action): CoreState =>
        tassign<CoreState, Partial<CoreState>>(state, {
          userConfigurationId: action.id,
          clientConfigurationLoading: true
        })
    ),
    on(fetchUserConfigurationSuccess, (state, action): CoreState => {
      return tassign<CoreState, Partial<CoreState>>(state, {
        clientConfiguration: action.response,
        clientConfigurationLoading: false
      });
    }),
    on(
      fetchUserConfigurationFailed,
      (state): CoreState =>
        tassign<CoreState, Partial<CoreState>>(state, {
          clientConfigurationLoading: false
        })
    ),
    on(
      fetchClosestAppointmentsRequested,
      (state): CoreState =>
        tassign<CoreState, Partial<CoreState>>(state, {
          closestAppointmentDate: null,
          closestAppointmentLoading: true
        })
    ),
    on(fetchClosestAppointmentsSuccess, (state, action): CoreState => {
      return tassign<CoreState, Partial<CoreState>>(state, {
        closestAppointmentDate:
          action.response.closestAppointmentDate || buildFullDate(new Date()),
        closestAppointmentLoading: false
      });
    }),
    on(fetchClosestAppointmentsFailed, (state): CoreState => {
      return tassign<CoreState, Partial<CoreState>>(state, {
        closestAppointmentLoading: false,
        appointmentSuggestionsLoading: false
      });
    }),
    on(fetchUnallocatedSlotsFailed, (state): CoreState => {
      return tassign<CoreState, Partial<CoreState>>(state, {
        appointmentSuggestionsLoading: false
      });
    }),
    on(
      fetchAppointmentSuggestionsRequested,
      (state): CoreState =>
        tassign<CoreState, Partial<CoreState>>(state, {
          appointmentSuggestions: [],
          appointmentSuggestionsLoading: true
        })
    ),
    on(setAvailableDaysInMonth, (state, action): CoreState => {
      const selectedDate = new Date(
        action.year,
        action.month,
        action.days.length > 0 ? action.days[0] : 1
      );

      return tassign<CoreState, Partial<CoreState>>(state, {
        selectedAppointmentDayInCalendar: selectedDate
      });
    }),
    on(fetchAppointmentSuggestionsSuccess, (state, action): CoreState => {
      let selectedDate = new Date();
      if (action.response.length > 0) {
        selectedDate = new Date(
          action.response[0].year,
          action.response[0].month - 1,
          action.response[0].day
        );
      }
      return tassign<CoreState, Partial<CoreState>>(state, {
        appointmentSuggestions: action.response,
        appointmentSuggestionsLoading: false,
        selectedAppointmentDayInCalendar: selectedDate
      });
    }),
    on(
      fetchAppointmentSuggestionsFailed,
      (state): CoreState =>
        tassign<CoreState, Partial<CoreState>>(state, {
          appointmentSuggestionsLoading: false
        })
    ),
    on(
      createAppointmentSuccess,
      (state): CoreState =>
        tassign<CoreState, Partial<CoreState>>(state, {
          currentStage: Stages.CONFIRMATION,
          appointmentSaved: true
        })
    )
  )
});
