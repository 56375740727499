import {Pipe, PipeTransform} from '@angular/core';
import {getNumberFormatted} from '../functions/format-numer.func';

@Pipe({
  name: 'formatNumber',
  standalone: true
})
export class FormatNumberPipe implements PipeTransform {
  public transform(phoneNumber: string): string {
    phoneNumber = phoneNumber.replace(/[^+0-9 ]/g, '');
    return getNumberFormatted(phoneNumber);
  }
}
