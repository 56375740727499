import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {BookingManagerComponent} from './components/booking-manager/booking-manager.component';
import {HttpClientModule} from '@angular/common/http';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {CalendarHeaderComponent} from './components/select-time/calendar-header/calendar-header.component';
import {Store, StoreModule} from '@ngrx/store';
import {metaReducers, reducers} from './reducers';
import {environment} from '../environments/environment';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {coreFeature} from './core/store/core/core.reducer';
import {EffectsModule} from '@ngrx/effects';
import {CoreEffects} from './core/store/core/core.effects';
import {TranslationsEntityModule} from './core/store/translations/translations.entity.module';
import {appInit} from './core/store/core/core.actions';
import {LanguageSelectorComponent} from './shared-components/language-selector/language-selector.component';
import {AppRoutingModule} from './app-routing.module';

@NgModule({
  declarations: [AppComponent, CalendarHeaderComponent],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    BookingManagerComponent,
    BrowserModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatIconModule,
    TranslationsEntityModule,
    EffectsModule.forRoot([CoreEffects]),
    StoreModule.forFeature(coreFeature),
    StoreModule.forRoot(reducers, {
      metaReducers
    }),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production
    }),
    LanguageSelectorComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private readonly store: Store) {
    this.store.dispatch(appInit());
  }
}
