import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MinutesBetweenDatesPipe} from '../../pipes/minutes-between-dates.pipe';
import {I18nPipe} from '../../pipes/i18n.pipe';
import {GetMonthNamePipe} from '../../pipes/get-month-name.pipe';
import {GetYearPipe} from '../../pipes/get-year.pipe';
import {GetDayNamePipe} from '../../pipes/get-day-name.pipe';
import {GetDatePipe} from '../../pipes/get-day.pipe';
import {AppointmentTimeRangeComponent} from '../../shared-components/appointment-time-range/appointment-time-range.component';
import {coreFeature} from '../../core/store/core/core.reducer';
import {Store} from '@ngrx/store';
import {CalendarBarComponent} from '../../shared-components/calendar-bar/calendar-bar.component';
import {FlatCardComponent} from '../../shared-components/flat-card/flat-card.component';

@Component({
  selector: 'app-confirm-appointment-time',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MinutesBetweenDatesPipe,
    I18nPipe,
    GetMonthNamePipe,
    GetYearPipe,
    GetDayNamePipe,
    GetDatePipe,
    AppointmentTimeRangeComponent,
    CalendarBarComponent,
    FlatCardComponent
  ],
  viewProviders: [I18nPipe],
  templateUrl: './confirm-appointment-time.component.html',
  styleUrls: ['./confirm-appointment-time.component.scss']
})
export class ConfirmAppointmentTimeComponent {
  public readonly customerConfiguration$ = this.store.select(
    coreFeature.selectClientConfiguration
  );

  public readonly userAppointmentInformation$ = this.store.select(
    coreFeature.selectUserAppointmentDate
  );

  constructor(private readonly store: Store) {}
}
