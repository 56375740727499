import {Injectable} from '@angular/core';
import {GetTranslation} from './models/get-translation.model';

@Injectable({
  providedIn: 'root'
})
export class LocalizationApiService {
  private readonly enMockedTranslations = {
    eab: {
      common: {
        continue: {
          translation: 'continue'
        },
        address: {
          translation: 'Address'
        },
        what_time_best: {
          translation: 'What time woks best?'
        },
        book_meeting_with_minutes: {
          translation: 'Book a %%% minutes meeting'
        },
        welcome_message: {
          translation: 'Schedule time to meet %%%'
        },
        confirm_meeting_for: {
          translation: 'Confirm meeting for'
        },
        salutation: {
          translation: 'Salutation'
        },
        first_name: {
          translation: 'First name'
        },
        last_name: {
          translation: 'Last name'
        },
        company_name: {
          translation: 'Company name'
        },
        email: {
          translation: 'Email'
        },
        phone_number: {
          translation: 'Phone number'
        },
        note: {
          translation: 'Note'
        },
        validator_field_required: {
          translation: 'Field required'
        },
        validator_max_length: {
          translation: 'Maximum length is %%% characters'
        },
        validator_email_not_valid: {
          translation: 'Email not valid'
        },
        phone_prefix: {
          translation: 'Country code'
        },
        male: {
          translation: 'Mister'
        },
        female: {
          translation: 'Mrs'
        },
        singlefemale: {
          translation: 'Miss'
        },
        validator_wrong_number: {
          translation: 'Wrong phone number'
        },
        change_date: {
          translation: 'Change Date'
        },
        confirm: {
          translation: 'Confirm'
        },
        booking_confirmed: {
          translation: 'Booking confirmed'
        },
        time: {
          translation: 'Time'
        },
        date: {
          translation: 'Date'
        },
        january: {
          translation: 'January'
        },
        february: {
          translation: 'February'
        },
        march: {
          translation: 'March'
        },
        april: {
          translation: 'April'
        },
        may: {
          translation: 'May'
        },
        june: {
          translation: 'June'
        },
        july: {
          translation: 'July'
        },
        august: {
          translation: 'August'
        },
        september: {
          translation: 'September'
        },
        october: {
          translation: 'October'
        },
        november: {
          translation: 'November'
        },
        december: {
          translation: 'December'
        },
        minutes: {
          translation: 'minutes'
        },
        monday: {
          translation: 'Monday'
        },
        tuesday: {
          translation: 'Tuesday'
        },
        wednesday: {
          translation: 'Wednesday'
        },
        thursday: {
          translation: 'Thursday'
        },
        friday: {
          translation: 'Friday'
        },
        saturday: {
          translation: 'Saturday'
        },
        sunday: {
          translation: 'Sunday'
        },
        not_valid_calendar_id: {
          translation: 'Provided calendar id is not valid'
        },
        available_time_error: {
          translation: 'time is not available'
        },
        invalid_time_error: {
          translation: 'selected time is invalid'
        },
        overlap_error: {
          translation: ' Appointment is overlapping with another appointment'
        },
        empty_subject_error: {
          translation: 'Appointment subject cannot be empty'
        },
        start_greater_end_error: {
          translation: 'Start date is greater than end date'
        },
        duration_less_minimum_error: {
          translation: 'Appointment duration is less than the minimum allowed'
        },
        duration_more_maximum_error: {
          translation: 'Appointment duration is more than the maximum allowed'
        },
        available_slot_error: {
          translation: 'Appointment is not within any available slots'
        },
        start_not_match_start_error: {
          translation: 'Start time doesnt match the appointment start time'
        },
        location_different_error: {
          translation:
            'Appointments location is different from appointment calendars location'
        },
        not_in_future_error: {
          translation: 'Appointment should not be made in the past'
        },
        no_slot_calendar_error: {
          translation:
            'No available slots found for the given appointment calendar'
        },
        not_find_calendar_error: {translation: 'Cannot find calendar account'},
        unauthorized_calendar_error: {translation: 'Calendar is unauthorized '},
        not_account_calendar_error: {
          translation: 'Cannot find calendar account'
        },
        error_title: {
          translation: 'Error message'
        },
        step1: {
          translation: 'Select a date & time'
        },
        step2: {
          translation: 'Other details'
        },
        step3: {
          translation: 'Confirmation'
        },
        detailed_consultation: {
          translation: 'Detailed Consultation'
        },
        no_time_available_for: {
          translation: 'No time available for selected month'
        },
        information: {
          translation: 'Information'
        },
        download_ics: {
          translation: 'ICS Download'
        },
        when: {
          translation: 'When'
        },
        confirmation_send: {
          translation: 'Confirmation was sent to your email %%%'
        },
        add_to: {
          translation: 'Add to'
        },
        english: {
          translation: 'English'
        },
        german: {
          translation: 'German'
        }
      }
    }
  };

  private readonly deMockedTranslations = {
    eab: {
      common: {
        continue: {
          translation: 'Weiter'
        },
        address: {
          translation: 'Adresse'
        },
        what_time_best: {
          translation: 'Welche Zeit ist am besten?'
        },
        book_meeting_with_minutes: {
          translation: 'Buchen Sie ein %%% minütiges Meeting'
        },
        welcome_message: {
          translation: 'Termin mit %%% vereinbaren'
        },
        confirm_meeting_for: {
          translation: 'Bestätigen Sie den Termin mit'
        },
        salutation: {
          translation: 'Anrede'
        },
        first_name: {
          translation: 'Vorname'
        },
        last_name: {
          translation: 'Nachname'
        },
        company_name: {
          translation: 'Firmenname'
        },
        email: {
          translation: 'E-Mail'
        },
        phone_number: {
          translation: 'Telefon-Nummer'
        },
        note: {
          translation: 'Hinweis'
        },
        validator_field_required: {
          translation: 'Feld erforderlich'
        },
        validator_max_length: {
          translation: 'Maximale Länge ist %%% Zeichen'
        },
        validator_email_not_valid: {
          translation: 'E-Mail nicht gültig'
        },
        phone_prefix: {
          translation: 'Landesvorwahl'
        },
        male: {
          translation: 'Herr'
        },
        female: {
          translation: 'Frau'
        },
        singlefemale: {
          translation: 'Fräulein'
        },
        validator_wrong_number: {
          translation: 'Keine telefonnummer'
        },
        change_date: {
          translation: 'Datum ändern'
        },
        confirm: {
          translation: 'Bestätigen'
        },
        booking_confirmed: {
          translation: 'Buchung bestätigt'
        },
        time: {
          translation: 'Uhrzeit'
        },
        date: {
          translation: 'Datum'
        },
        january: {
          translation: 'Januar'
        },
        february: {
          translation: 'Februar'
        },
        march: {
          translation: 'März'
        },
        april: {
          translation: 'April'
        },
        may: {
          translation: 'Mai'
        },
        june: {
          translation: 'Juni'
        },
        july: {
          translation: 'Juli'
        },
        august: {
          translation: 'August'
        },
        september: {
          translation: 'September'
        },
        october: {
          translation: 'Oktober'
        },
        november: {
          translation: 'November'
        },
        december: {
          translation: 'Dezember'
        },
        minutes: {
          translation: 'minuten'
        },
        monday: {
          translation: 'Montag'
        },
        tuesday: {
          translation: 'Dienstag'
        },
        wednesday: {
          translation: 'Mittwoch'
        },
        thursday: {
          translation: 'Donnerstag'
        },
        friday: {
          translation: 'Freitag'
        },
        saturday: {
          translation: 'Samstag'
        },
        sunday: {
          translation: 'Sonntag'
        },
        not_valid_calendar_id: {
          translation: 'Die angegebene Kalender-ID ist nicht gültig'
        },
        available_time_error: {
          translation: 'Zeit ist nicht verfügbar'
        },
        invalid_time_error: {
          translation: 'ausgewählte Zeit ist ungültig'
        },
        overlap_error: {
          translation: 'Der Termin überschneidet sich mit einem anderen Termin'
        },
        empty_subject_error: {
          translation: 'Betreff des Termins kann nicht leer sein'
        },
        start_greater_end_error: {
          translation: 'Das Startdatum ist nach dem Enddatum'
        },
        duration_less_minimum_error: {
          translation:
            'Die Dauer des Termins ist kürzer als das erlaubte Minimum'
        },
        duration_more_maximum_error: {
          translation:
            'Die Dauer des Termins ist länger als die maximal zulässige Dauer'
        },
        available_slot_error: {
          translation: 'Der Termin liegt nicht in einem der verfügbaren Slots'
        },
        start_not_match_start_error: {
          translation:
            'Startzeit stimmt nicht mit der Startzeit des Termins überein'
        },
        location_different_error: {
          translation:
            'Der Ort des Termins unterscheidet sich vom Ort des Terminkalenders'
        },
        not_in_future_error: {
          translation: 'Der Termin sollte nicht in der Vergangenheit liegen'
        },
        no_slot_calendar_error: {
          translation:
            'Keine verfügbaren Termine für den angegebenen Terminkalender gefunden'
        },
        not_find_calendar_error: {
          translation: 'Kalenderkonto kann nicht gefunden werden'
        },
        unauthorized_calendar_error: {
          translation: 'Der Kalender ist nicht autorisiert  '
        },
        not_account_calendar_error: {
          translation: 'Kalenderkonto kann nicht gefunden werden'
        },
        error_title: {
          translation: 'Fehlermeldung'
        },
        step1: {
          translation: 'Wählen Sie ein Datum und eine Uhrzeit'
        },
        step2: {
          translation: 'Andere Details'
        },
        step3: {
          translation: 'Bestätigung'
        },
        no_time_available_for: {
          translation: 'Für den gewählten Monat ist keine Zeit verfügbar'
        },
        information: {
          translation: 'Informationen'
        },
        download_ics: {
          translation: 'ICS herunterladen'
        },
        when: {
          translation: 'Wann'
        },
        confirmation_send: {
          translation: 'Bestätigung wurde an %%% gesendet'
        },
        add_to: {
          translation: 'Hinzufügen zu'
        },
        english: {
          translation: 'Englisch'
        },
        german: {
          translation: 'Deutsch'
        }
      }
    }
  };

  public fetchTranslations(translationParams: GetTranslation) {
    switch (translationParams.language) {
      case 1:
        return this.enMockedTranslations;
      case 2:
        return this.deMockedTranslations;
      default:
        return this.enMockedTranslations;
    }
  }
}
