import {Pipe, PipeTransform} from '@angular/core';
import {map, Observable, of} from 'rxjs';
import {I18nPipe} from './i18n.pipe';
import {TranslationsKeys} from '../core/api/models/translation.model';
import {getDayName} from '../functions/get-day-name.func';

@Pipe({
  name: 'getDayName',
  standalone: true
})
export class GetDayNamePipe implements PipeTransform {
  constructor(private readonly i18n: I18nPipe) {}

  public transform(
    value: Date | string | undefined,
    longName: boolean = false
  ): Observable<string> {
    if (!value) {
      return of('');
    }

    value = new Date(value);

    return this.i18n
      .transform(getDayName(value.getDay()) as TranslationsKeys)
      .pipe(
        map((text) => (longName ? text : (text as string).substring(0, 3)))
      );
  }
}
