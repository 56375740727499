<ng-container *ngIf="selectedHour$ | async as selectedHour">
  <div
    *ngIf="customerConfiguration$ | async as appointmentConfiguration"
    class="calendar-container"
  >
    <app-button
      (click)="downloadICS(appointmentConfiguration, selectedHour)"
      [buttonType]="'stroke'"
      [icon]="'cloud_download'"
      [label]="'EAB.COMMON.DOWNLOAD_ICS'"
      class="calendar-container__download_ics"
    >
    </app-button>

    <div class="d-flex align-center">
      <div class="add-to-calendar">{{
        'EAB.COMMON.ADD_TO' | i18n | async
      }}</div>

      <button
        (click)="
          addCalendar(
            SUPPORTED_CALENDARS.GOOGLE,
            appointmentConfiguration,
            selectedHour
          )
        "
        class="add-to-calendar"
        mat-button
      >
        <img src="assets/icon-google-t5.svg" />
      </button>

      <button
        (click)="
          addCalendar(
            SUPPORTED_CALENDARS.OFFICE365,
            appointmentConfiguration,
            selectedHour
          )
        "
        class="add-to-calendar"
        mat-button
      >
        <img src="assets/icon-office365-t5.svg" />
      </button>

      <button
        (click)="
          addCalendar(
            SUPPORTED_CALENDARS.OUTLOOK_LIVE,
            appointmentConfiguration,
            selectedHour
          )
        "
        class="add-to-calendar"
        mat-button
      >
        <img src="assets/icon-outlookcom-t5.svg" />
      </button>
    </div>
  </div>
</ng-container>
