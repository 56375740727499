// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  version: 1.0,
  production: false,
  secretaryImage: 'EBUERO_STATIC_PATH_PLACEHOLDER/web-components/global/images/secretary_homepage.png',
  calendarUrl: 'EBUERO_SERVER_PATH_PLACEHOLDER/calendar/v1'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
