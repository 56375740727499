import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  fetchTranslationsRequested,
  fetchTranslationsSuccess
} from './translations.actions';
import {LocalizationApiService} from '../../api/localization-api.service';
import {map} from 'rxjs/operators';

@Injectable()
export class TranslationsEffects {
  public onLoginFetchTranslations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchTranslationsRequested),
      map((action) =>
        this.localizationApiService.fetchTranslations({
          language: action.payload.translationsParameters.language
        })
      ),
      map((response) => fetchTranslationsSuccess({response}))
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly localizationApiService: LocalizationApiService
  ) {}
}
