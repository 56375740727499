import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {TranslationsKeys} from '../../core/api/models/translation.model';
import {I18nPipe} from '../../pipes/i18n.pipe';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    I18nPipe,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Output() public clicked = new EventEmitter();
  @Input() public disabled: boolean = false;
  @Input() public loading: boolean | null = false;
  @Input() public label!: TranslationsKeys;
  @Input() public icon!: string;
  @Input() public buttonType: 'basic' | 'stroke' = 'basic';

  public mouseClick() {
    if (this.disabled || this.loading) {
      return;
    }

    this.clicked.emit();
  }
}
