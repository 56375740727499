<div class="calendar-header">
  <button
    (click)="previousClicked()"
    [disabled]="checkDisabled()"
    mat-icon-button
  >
    <mat-icon>chevron_left</mat-icon>
  </button>
  <span class="calendar-header__label">{{ periodLabel }}</span>
  <button (click)="nextClicked()" mat-icon-button>
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>
