import {CalendarUnallocatedSlotsModel} from '../core/models/calendar-unallocated-slots.adapter.model';
import {addMinutesToDate} from './add-minutes-to-date.func';

const TIMESPAN_IN_MINUTES = 5;
const SKIP_TIMESPAN_IN_MINUTES = 1440;

export function generateCalendarAvailabilityFunc(
  unallocatedSlots: CalendarUnallocatedSlotsModel[]
): {days: number[]; year: number; month: number} {
  const dates: number[] = [];
  let year: number = -1;
  let month: number = -1;

  for (let slot = 0; slot < unallocatedSlots.length; slot++) {
    let starDate = new Date(unallocatedSlots[slot].startTime);
    let endDate = new Date(unallocatedSlots[slot].endTime);
    while (starDate <= endDate) {
      const dayOfMonth = starDate.getDate();
      if (dates.includes(dayOfMonth)) {
        starDate = addMinutesToDate(starDate, SKIP_TIMESPAN_IN_MINUTES);
        starDate.resetToDayStart();
      } else {
        dates.push(dayOfMonth);
        year = starDate.getFullYear();
        month = starDate.getMonth();
        starDate = addMinutesToDate(starDate, TIMESPAN_IN_MINUTES);
      }
    }
  }

  return {
    days: [...new Set(dates)].sort(function (a, b) {
      return a - b;
    }),
    month,
    year
  };
}
