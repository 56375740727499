import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  AppointmentSuggestionApiModel,
  AppointmentSuggestionFullApiModel
} from './models/appointment-suggestion-full-api.model';
import {AppointmentConfigurationApiResponseModel} from './models/appointment-configuration-api.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {CreateAppointmentApiModel} from './models/create-appointment.api.model';
import {
  CalendarConfigurationAdapter,
  CalendarConfigurationModel
} from '../models/calendar-configuration.model';
import {map} from 'rxjs/operators';
import {
  ClosestAppointmentAdapter,
  ClosestAppointmentModel
} from '../models/appointment-suggestions.model';
import {
  BookedAppointmentAdapter,
  BookedAppointmentModel
} from './models/appointment-booked.model';
import {AppointmentBookedApiResponseModel} from './models/appointment-booked-api-response.model';
import {CalendarUnallocatedSlotsAdapter} from '../models/calendar-unallocated-slots.adapter.model';
import {
  UnallocatedSlotApiResponseModel,
  UnallocatedSlotsApiResponseModel
} from './models/unallocated-slots.api.model';
import {ApiResponseModel} from './models/api-response.model';

@Injectable({
  providedIn: 'root'
})
export class CalendarApiService {
  private readonly ENDPOINTS = {
    CREATE_APPOINTMENT: (appointmentCalendarId: number) =>
      `${environment.calendarUrl}/appointmentcalendars/${appointmentCalendarId}/external-booking/appointments`,
    GET_CALENDAR_INFORMATION: (url: string) =>
      `${environment.calendarUrl}/appointmentcalendars/external-booking/${url}`,
    GET_UNALLOCATED_SLOTS: (
      calendarId: number,
      startDate: string,
      endDate: string
    ) =>
      `${environment.calendarUrl}/availability/external-booking/unallocated-slots/${calendarId}/?startDate=${startDate}&endDate=${endDate}`,
    GET_CLOSEST_APPOINTMENTS: (
      appointmentCalendarId: number,
      appointmentDuration: number
    ) =>
      `${environment.calendarUrl}/availability/external-booking/unallocated-slots/closest/${appointmentCalendarId}?durationInMin=${appointmentDuration}`,
    GET_APPOINTMENT_SUGGESTIONS: (
      appointmentCalendarId: number,
      startDate: string,
      endDate: string,
      durationInMin: number
    ) =>
      `${environment.calendarUrl}/appointmentcalendars/${appointmentCalendarId}/external-booking/appointmentsuggestions?durationInMin=${durationInMin}&page=0&size=125&startDate=${startDate}&endDate=${endDate}`
  };

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly calendarConfigurationAdapter: CalendarConfigurationAdapter,
    private readonly calendarUnallocatedSlotsAdapter: CalendarUnallocatedSlotsAdapter,
    private readonly closestAppointmentAdapter: ClosestAppointmentAdapter,
    private readonly bookedAppointmentAdapter: BookedAppointmentAdapter
  ) {}

  public getClosestAppointments(
    appointmentCalendarId: number,
    appointmentDuration: number
  ): Observable<ClosestAppointmentModel> {
    return this._httpClient
      .get<ApiResponseModel<UnallocatedSlotApiResponseModel[]>>(
        this.ENDPOINTS.GET_CLOSEST_APPOINTMENTS(
          appointmentCalendarId,
          appointmentDuration
        )
      )
      .pipe(map((data) => this.closestAppointmentAdapter.adapt(data)));
  }

  public createNewAppointment(
    appointmentCalendarId: number,
    creteAppointment: CreateAppointmentApiModel
  ): Observable<BookedAppointmentModel> {
    return this._httpClient
      .post<AppointmentBookedApiResponseModel>(
        this.ENDPOINTS.CREATE_APPOINTMENT(appointmentCalendarId),
        creteAppointment
      )
      .pipe(map((data) => this.bookedAppointmentAdapter.adapt(data)));
  }

  public getUserDetails(
    configurationName: string
  ): Observable<CalendarConfigurationModel> {
    return this._httpClient
      .get<AppointmentConfigurationApiResponseModel>(
        this.ENDPOINTS.GET_CALENDAR_INFORMATION(configurationName)
      )
      .pipe(map((data) => this.calendarConfigurationAdapter.adapt(data)));
  }

  public getAppointmentSuggestions(
    appointmentCalendarId: number,
    startDateTime: string,
    endDateTime: string,
    durationInMinutes: number
  ): Observable<AppointmentSuggestionApiModel[]> {
    return this._httpClient
      .get<AppointmentSuggestionFullApiModel>(
        this.ENDPOINTS.GET_APPOINTMENT_SUGGESTIONS(
          appointmentCalendarId,
          startDateTime,
          endDateTime,
          durationInMinutes
        )
      )
      .pipe(
        map((items) => {
          return items.content;
        })
      );
  }

  public getUnallocatedSlots(
    appointmentCalendarId: number,
    startDate: string,
    endDate: string
  ) {
    return this._httpClient
      .get<UnallocatedSlotsApiResponseModel>(
        this.ENDPOINTS.GET_UNALLOCATED_SLOTS(
          appointmentCalendarId,
          startDate,
          endDate
        )
      )
      .pipe(map((data) => this.calendarUnallocatedSlotsAdapter.adapt(data)));
  }
}
