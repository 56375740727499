import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Store} from '@ngrx/store';
import {selectAvailableAppointmentTime} from '../../core/store/core/core.selectors';
import {I18nPipe} from '../../pipes/i18n.pipe';
import {BehaviorSubject, Observable, tap} from 'rxjs';
import {coreFeature} from '../../core/store/core/core.reducer';
import {SpinnerIndicatorComponent} from '../spinner-indicator/spinner-indicator.component';
import {AppointmentSuggestionHourViewModel} from '../../models/appointment-suggestion-view-model.model';
import {userSelectedAppointment} from '../../core/store/core/core.actions';

@Component({
  selector: 'app-time-picker',
  standalone: true,
  imports: [CommonModule, I18nPipe, SpinnerIndicatorComponent],
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent {
  public readonly suggestedTimes$ = this.store.select(
    selectAvailableAppointmentTime
  );
  public readonly selectedIndex$ = new BehaviorSubject<number>(-1);
  public readonly suggestedTimesLoading$ = this.store
    .select(coreFeature.selectAppointmentSuggestionsLoading)
    .pipe(
      tap((loading) => {
        if (loading) {
          this.selectedIndex$.next(-1);
        }
      })
    );
  public selectedDate$: Observable<Date> = this.store.select(
    coreFeature.selectSelectedAppointmentDayInCalendar
  );

  constructor(private readonly store: Store) {}

  public timeSelected(index: number) {
    this.selectedIndex$.next(index);
  }

  public selected(selectedHour: AppointmentSuggestionHourViewModel) {
    this.store.dispatch(
      userSelectedAppointment({selectedAppointment: selectedHour})
    );
  }
}
