import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy
} from '@angular/core';
import {Subject, takeUntil} from 'rxjs';
import {MatCalendar} from '@angular/material/datepicker';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats
} from '@angular/material/core';
import {Store} from '@ngrx/store';
import {changeCalendarMonth} from '../../../core/store/core/core.actions';

@Component({
  selector: 'app-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarHeaderComponent<D> implements OnDestroy {
  private readonly _destroyed = new Subject<void>();

  constructor(
    private readonly _calendar: MatCalendar<D>,
    private readonly _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private readonly _dateFormats: MatDateFormats,
    changeDetectorRef: ChangeDetectorRef,
    private readonly store: Store
  ) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => changeDetectorRef.markForCheck());
  }

  public get periodLabel() {
    return this._dateAdapter.format(
      this._calendar.activeDate,
      this._dateFormats.display.monthYearA11yLabel
    );
  }

  public ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public checkDisabled(): boolean {
    const selectedMonth = this._dateAdapter.getMonth(this._calendar.activeDate);
    const selectedYear = this._dateAdapter.getYear(this._calendar.activeDate);
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    return selectedYear === currentYear && selectedMonth === currentMonth;
  }

  public previousClicked() {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(
      this._calendar.activeDate,
      -1
    );
    this.fetchSuggestions(this._calendar.activeDate as unknown as Date);
  }

  public nextClicked() {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(
      this._calendar.activeDate,
      1
    );
    this.fetchSuggestions(this._calendar.activeDate as unknown as Date);
  }

  private fetchSuggestions(date: Date) {
    this.store.dispatch(changeCalendarMonth({date}));
  }
}
