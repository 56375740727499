export function buildFullDate(date: string | Date, skipZ = false): string {
  const dt = new Date(date);
  return `${dt.getFullYear()}-${(dt.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${dt.getDate().toString().padStart(2, '0')}T${dt
    .getHours()
    .toString()
    .padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}:${dt
    .getSeconds()
    .toString()
    .padStart(2, '0')}${skipZ ? '' : 'Z'}`;
}

export function addMinutesToDate(date: string | Date, minutes: number): Date {
  const dt = new Date(date);
  return new Date(dt.getTime() + minutes * 60000);
}

export function addMinutesToStringDate(
  date: string | Date,
  minutes: number
): string {
  const newDt = addMinutesToDate(date, minutes);
  return buildFullDate(newDt);
}
