import {Injectable} from '@angular/core';
import {Adapter} from '../api/adapters/adapter';
import {AppointmentConfigurationApiResponseModel} from '../api/models/appointment-configuration-api.model';

export class CalendarConfigurationModel {
  constructor(
    public calendarId: number,
    public appointmentName: string,
    public infoToCaller: string,
    public appointmentLocation: string,
    public calendarOwnerFirstName: string,
    public calendarOwnerLastName: string,
    public calendarOwnerCompanyName: string,
    public appointmentDuration: number,
    public calendarOwnerFullName: string
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class CalendarConfigurationAdapter
  implements Adapter<CalendarConfigurationModel>
{
  public adapt(
    item: AppointmentConfigurationApiResponseModel
  ): CalendarConfigurationModel {
    return new CalendarConfigurationModel(
      item.result.id,
      item.result.name,
      item.result.infoToCaller,
      item.result.defaultLocation,
      item.result.calendar.calendarAccountOwner.firstName,
      item.result.calendar.calendarAccountOwner.lastName,
      item.result.calendar.calendarAccountOwner.companyName,
      item.result.appointmentMinDuration,
      `
      ${item.result.calendar.calendarAccountOwner.firstName}
      ${item.result.calendar.calendarAccountOwner.lastName}
      (${item.result.calendar.calendarAccountOwner.companyName})`
    );
  }
}
