import {AppointmentSuggestionViewModel} from '../models/appointment-suggestion-view-model.model';
import {AppointmentSuggestionApiModel} from '../core/api';

function splitDate(date: string): {year: number; month: number; day: number} {
  const [year, month, day] = date.split('-');
  return {year: +year, month: +month, day: +day};
}

function splitHour(hourText: string): {hour: number; minute: number} {
  const [hour, minute] = hourText.split(':');
  return {hour: +hour, minute: +minute};
}

function splitStringDate(date: string): {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
} {
  const [fulldate, fullhour] = date.split('T');
  const dateObject = splitDate(fulldate);
  const hourObject = splitHour(fullhour);
  return {...dateObject, ...hourObject};
}

export function convertAppointmentSuggestionsToDaySuggestions(
  suggestions: AppointmentSuggestionApiModel[]
): AppointmentSuggestionViewModel[] {
  const result: AppointmentSuggestionViewModel[] = [];

  suggestions.forEach((suggestion) => {
    const startTime = splitStringDate(suggestion.startTime);
    const endTime = splitStringDate(suggestion.endTime);

    const newHour = {
      startHour: startTime.hour.toString().padStart(2, '0'),
      endHour: endTime.hour.toString().padStart(2, '0'),
      endMinutes: endTime.minute.toString().padStart(2, '0'),
      startMinutes: startTime.minute.toString().padStart(2, '0'),
      orginalStartTime: suggestion.startTime,
      orginalEndTime: suggestion.endTime
    };

    const exitingDate = result.find(
      (x) =>
        x.year === startTime.year &&
        x.month === startTime.month &&
        x.day === startTime.day
    );

    if (!!exitingDate) {
      exitingDate.hours.push(newHour);
    } else {
      result.push({
        day: startTime.day,
        month: startTime.month,
        year: startTime.year,
        hours: [newHour]
      });
    }
  });

  return result;
}
