import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {coreFeature} from '../../core/store/core/core.reducer';
import {CalendarService} from '../../services/calendar.service';
import {Store} from '@ngrx/store';
import {SupportedCalendarsEnum} from '../../models/supported-calendars.enum';
import {CalendarConfigurationModel} from '../../core/models/calendar-configuration.model';
import {AppointmentSuggestionHourViewModel} from '../../models/appointment-suggestion-view-model.model';
import {MatIconModule} from '@angular/material/icon';
import {I18nPipe} from '../../pipes/i18n.pipe';
import {MatButtonModule} from '@angular/material/button';
import {ButtonComponent} from '../button/button.component';

@Component({
  selector: 'app-calendar-bar',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    I18nPipe,
    MatButtonModule,
    ButtonComponent
  ],
  templateUrl: './calendar-bar.component.html',
  styleUrls: ['./calendar-bar.component.scss']
})
export class CalendarBarComponent {
  public readonly customerConfiguration$ = this.store.select(
    coreFeature.selectClientConfiguration
  );

  public readonly selectedHour$ = this.store.select(
    coreFeature.selectSelectedAppointment
  );

  public SUPPORTED_CALENDARS = SupportedCalendarsEnum;

  constructor(
    private readonly calendarService: CalendarService,
    private readonly store: Store
  ) {}

  public addCalendar(
    calendarType: SupportedCalendarsEnum,
    appointmentConfigurationApiModel: CalendarConfigurationModel,
    selectedHour: AppointmentSuggestionHourViewModel
  ) {
    const link = this.calendarService.generateLink(calendarType, {
      title: appointmentConfigurationApiModel.appointmentName,
      startDate: selectedHour!!.orginalStartTime,
      endDate: selectedHour!!.orginalEndTime,
      details: appointmentConfigurationApiModel.infoToCaller,
      location: appointmentConfigurationApiModel.appointmentLocation
    });

    window.open(link, '_blank');
  }

  public downloadICS(
    appointmentConfigurationApiModel: CalendarConfigurationModel,
    selectedHour: AppointmentSuggestionHourViewModel
  ) {
    const events = this.calendarService.createEvent([
      {
        start: new Date(selectedHour!!.orginalStartTime),
        end: new Date(selectedHour!!.orginalEndTime),
        description: appointmentConfigurationApiModel.infoToCaller,
        location: appointmentConfigurationApiModel.appointmentLocation,
        summary: appointmentConfigurationApiModel.appointmentName
      }
    ]);

    this.calendarService.download(
      appointmentConfigurationApiModel.appointmentName,
      events
    );
  }
}
